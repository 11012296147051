import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  Heading,
  Paragraph,
  Modal,
  Typography,
  Search,
  DisplayCard,
  Tooltip,
  toaster,
} from "@trace-one/design-system";

import { CumdAPI } from "../../../../apis";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { CompanyStatus } from "../../../../shared/constants";
import useCompanyActivities from "../../../../shared/hooks/useCompanyActivity";
import useToast from "../../../../shared/hooks/useToast";

import styles from "./CompanySubscriptionList.module.less";
import useGroupDetails from "./hooks/useGroupDetails";
export interface ShareSubscriptionModalProps {
  setIsModalVisible?: any;
  isModalVisible?: boolean;
  companyGroupId?: string;
  companyOwnerId?: string;
  subscriptionId?: string;
  setSharedCompaniesLength?: any;
}

const ShareSubscriptionModal: React.FC<ShareSubscriptionModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  companyGroupId,
  companyOwnerId,
  subscriptionId,
  setSharedCompaniesLength,
}) => {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const languageCode = useSelector(selectUserLanguageCode);

  const [companies, setCompanies] = useState<any>();
  const [sharedSubscriptions, setSharedSubscriptions] = useState<any>();

  const [companyNameSearch, setCompanyNameSearch] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();

  const companyActivities = useCompanyActivities(languageCode);
  const groupData = useGroupDetails(companyGroupId);

  const getActivityText = (activityId, companyActivities) => {
    const activity = companyActivities?.find(
      activity => Number(activity?.itemCode) === activityId
    );
    return activity ? activity.text : "";
  };

  const companyIds = groupData?.companies?.map(company => company.companyId);

  const fetchCompanies = async () => {
    try {
      let { data } = await CumdAPI.getCompaniesByFiltersForToAdmin(
        {
          companyStatuses: [CompanyStatus.ENABLED],
          companyIds: companyIds,
        },
        {
          searchText: companyNameSearch,
        }
      );

      setCompanies(data);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [companyNameSearch, companyIds?.length]);

  const companiesToDisplay = companies?.companies?.filter(company =>
    companyIds?.includes(company.companyId)
  );

  const fetchSharedSubscriptions = async () => {
    if (companyGroupId !== null) {
      try {
        const { data } = await CumdAPI.getSharedSubscriptionById({
          ownerCompanyId: companyOwnerId,
        });
        setSharedSubscriptions(data);

        const totalSharedCompaniesLength = data?.reduce(
          (acc, subscription) =>
            acc + (subscription?.sharedCompanies?.length || 0),
          0
        );

        setSharedCompaniesLength(totalSharedCompaniesLength);
      } catch (error) {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    fetchSharedSubscriptions();
  }, [companyOwnerId]);

  const subscriptionOwnerCompany = companiesToDisplay?.find(
    company => company.companyId === companyOwnerId
  );

  const onSearchChange = e => {
    const newValue = e.target.value;
    setCompanyNameSearch(newValue);
  };
  const onClearSearch = () => setCompanyNameSearch("");

  const onSearch = () => {
    setCompanyNameSearch(companyNameSearch);
  };
  const onClose = () => {
    setIsModalVisible(false);
    setCompanyId(undefined);
    onClearSearch();
  };

  const handleShareSubscription = async () => {
    try {
      await CumdAPI.shareSubscriptionBetweenTwoCompanies(subscriptionId, {
        companyId: companyId,
      });

      await fetchSharedSubscriptions();

      toaster.open({
        message: formatMessage({
          id: "group.share.subscription.modal.confirmation.toast.title",
        }),
        description: formatMessage(
          {
            id: "group.share.subscription.modal.confirmation.toast.text",
          },
          { companyDisplayName: companyName }
        ),
        type: "confirmation",
      });
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage(
          {
            id: "group.share.subscription.modal.error.toast.text",
          },
          {
            companyDisplayName: companyName,
          }
        ),

        type: "alert",
      });
    }
  };

  const getTooltipTextAndDisabled = company => {
    if (subscriptionOwnerCompany?.companyId === company?.companyId) {
      return {
        tooltipText: formatMessage({
          id: "group.share.subscription.modal.tooltip.owner.description",
        }),
        isDisabled: true,
        dataTestId: "share-subscription-modal-tooltip-owner",
      };
    }

    const hasSharedSubscription = sharedSubscriptions?.some(sub =>
      sub.sharedCompanies?.some(
        sharedCompany =>
          sharedCompany.associatedCompanyId === company?.companyId
      )
    );

    if (hasSharedSubscription) {
      return {
        tooltipText: formatMessage({
          id: "group.share.subscription.modal.tooltip.shared.description",
        }),
        isDisabled: true,
        dataTestId: "share-subscription-modal-tooltip-alreadyShared",
      };
    }

    return {
      tooltipText: "",
      isDisabled: false,
    };
  };

  return (
    <div>
      <Modal
        size="l"
        open={isModalVisible}
        title={formatMessage({ id: "group.share.subscription" })}
        headerChildren={
          <div className={styles.sharedSubscriptionModalHeader}>
            <Typography
              variant="heading-xs"
              color="grey-5"
              data-test-id="share-subscription-modal-title"
            >
              {formatMessage({
                id: "group.share.subscription.modal.description",
              })}
            </Typography>
            <Search
              placeholder={formatMessage({ id: "general.enterCompanyName" })}
              onChange={onSearchChange}
              onClearSearch={onClearSearch}
              onSearch={onSearch}
              value={companyNameSearch}
              data-test-id="share-subscription-modal-company-name-search"
            />
          </div>
        }
        primaryButtonText={formatMessage({
          id: "general.confirm",
        }).toUpperCase()}
        onPrimaryButtonClick={handleShareSubscription}
        secondaryButtonText={formatMessage({
          id: "general.cancel",
        }).toUpperCase()}
        onSecondaryButtonClick={onClose}
        onCancel={onClose}
        data-test-id="share-subscription-modal"
      >
        <div>
          <div>
            <Heading size="xs">
              {formatMessage({ id: "general.companies" })}
            </Heading>
            <Paragraph variant="body-xs">
              ({companiesToDisplay?.length})
            </Paragraph>
          </div>
          {companiesToDisplay?.map(company => {
            const { tooltipText, isDisabled } =
              getTooltipTextAndDisabled(company);
            return (
              <Tooltip
                key={company?.companyId}
                title={tooltipText}
                placement="top"
                size="small"
              >
                <DisplayCard
                  key={company?.companyId}
                  title={company?.companyDisplayName}
                  subtitle={getActivityText(
                    company?.companyActivityId,
                    companyActivities
                  )}
                  disabled={isDisabled}
                  iconName="company"
                  selected={companyId === company?.companyId}
                  onClick={() => {
                    if (!isDisabled) {
                      setCompanyId(company?.companyId);
                      setCompanyName(company?.companyDisplayName);
                    }
                  }}
                  data-test-id="share-subscription-modal-display-card"
                />
              </Tooltip>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};
export default ShareSubscriptionModal;
