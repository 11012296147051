import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu, MenuItemProps } from "antd";

import { CumdAPI } from "apis";
import { SubscriptionData } from "models";

import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { SubscriptionStatus } from "shared/constants";
import useToast from "shared/hooks/useToast";

import usePermissions from "../../../../core/oidc/usePermissions";

import styles from "./CompanySubscriptionList.module.less";
import ShareSubscriptionModal from "./ShareSubscriptionModal";

interface SubscriptionActionProps {
  companyId: string;
  data: SubscriptionData;
  refetchData: () => void;
  companyGroupId?: string;
  setSharedCompaniesLength?: any;
}

const SubscriptionAction: React.FC<SubscriptionActionProps> = ({
  data,
  companyGroupId,
  refetchData,
  setSharedCompaniesLength,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const languageCode = useSelector(selectUserLanguageCode);

  const [isShareSubscriptionModalVisible, setIsShareSubscriptionModalVisible] =
    useState<boolean>(false);

  const onSubscriptionChangeToInactive = async () => {
    try {
      await CumdAPI.deleteSubscription(data.subscriptionId);
      refetchData();
    } catch (error) {
      toast.fetchError({ error });
    }
  };

  const handleOpenSubscriptionModal = () => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          confirmationModalTitle={formatMessage({
            id: "companySubscriptionList.closeSubscription",
          }).toUpperCase()}
          onConfirm={onSubscriptionChangeToInactive}
          confirmBtnText={formatMessage({ id: "general.yes" })}
          cancelBtnText={formatMessage({ id: "general.no" })}
        >
          <section className={styles.closeSubscriptionModal}>
            <div className={styles.description}>
              <p>
                {formatMessage({
                  id: "companySubscriptionList.closeSubscriptionDescription",
                })}
              </p>
            </div>
            <p className={styles.confirmationQuestion}>
              {formatMessage({
                id: "general.action.confirmation.text",
              })}
            </p>
          </section>
        </ConfirmationModal>
      ),
    });
  };

  const handleOpenShareSubscriptionModal = () => {
    setIsShareSubscriptionModalVisible(true);
  };

  const Menus: (MenuItemProps & {
    "data-test-id": string;
    visible: boolean;
  })[] = [
    {
      onClick: () => {
        history.push(`/companies/subscriptions/${data.subscriptionId}/edit`);
      },
      "data-test-id": "ad-edit-subscription",
      children: formatMessage({
        id: "companySubscriptionForm.editSubscription",
      }),
      visible:
        (hasRoleSuperAdmin || hasRoleAdministrator) &&
        data.status === SubscriptionStatus.ACTIVE,
    },

    {
      onClick: handleOpenShareSubscriptionModal,
      "data-test-id": "ad-share-subscription",
      children: formatMessage({
        id: "group.share.subscription",
      }),
      visible: companyGroupId !== null && data.applicationTypeId === 100,
    },

    {
      onClick: handleOpenSubscriptionModal,
      "data-test-id": "ad-close-subscription",
      children: formatMessage({
        id: "companySubscriptionList.closeSubscription",
      }),
      visible:
        (hasRoleSuperAdmin || hasRoleAdministrator) &&
        data.status === SubscriptionStatus.ACTIVE,
    },
  ].filter(({ visible }) => visible);

  return (
    <>
      <ActionDropdown
        data-test-id="ad-subscription-action"
        disabled={Menus.length === 0}
        overlay={
          <Menu>
            {Menus.map(({ visible, ...menuItemProps }, index) => (
              <Menu.Item
                key={index}
                {...menuItemProps}
                style={{ fontSize: "16px", lineHeight: "24px" }}
              />
            ))}
          </Menu>
        }
      />
      <ShareSubscriptionModal
        isModalVisible={isShareSubscriptionModalVisible}
        setIsModalVisible={setIsShareSubscriptionModalVisible}
        companyGroupId={companyGroupId}
        companyOwnerId={data?.companyId}
        subscriptionId={data?.subscriptionId}
        setSharedCompaniesLength={setSharedCompaniesLength}
      />
    </>
  );
};

export default SubscriptionAction;
