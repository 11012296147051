export type StorageType = Readonly<{
  set: (value: object | string) => void;
  get: () => any;
  remove: () => void;
}>;

const buildStorage =
  (storage: Storage) =>
  (key: string): StorageType =>
    Object.freeze({
      set: (value: object | string) => {
        if (typeof value === "object") {
          const result = JSON.stringify(value);
          storage?.setItem(key, result);
        } else {
          storage?.setItem(key, value);
        }
      },
      get: () => {
        const result = storage?.getItem(key);
        try {
          return JSON.parse(result);
        } catch (error) {
          return result;
        }
      },
      remove: () => {
        storage?.removeItem(key);
      },
    });

export const buildSessionStorage = buildStorage(sessionStorage);
export const buildLocalStorage = buildStorage(localStorage);

export const SessionKeys = Object.freeze({
  BACK_TO_APP: "back-to-app",

  COMPANY_ADMIN_USER_PAGINATION: "company-admin-user-pagination",
  COMPANY_ADMIN_USER_FILTERS: "company-admin-user-filters",

  TO_ADMIN_REGISTERED_USER_PAGINATION: "to-admin-registered-user-pagination",
  TO_ADMIN_REGISTERED_USER_FILTERS: "to-admin-registered-user-filters",

  TO_ADMIN_PENDING_USER_PAGINATION: "to-admin-pending-user-pagination",
  TO_ADMIN_PENDING_USER_FILTERS: "to-admin-pending-user-filters",
  // TO_ADMIN_USER_FILTERS: "to-admin-user-filters",

  TO_ADMIN_REGISTERED_COMPANY_PAGINATION:
    "to-admin-registered-company-pagination",
  TO_ADMIN_REGISTERED_COMPANY_FILTERS: "to-admin-registered-company-filters",

  TO_ADMIN_PENDING_COMPANY_PAGINATION: "to-admin-pending-company-pagination",
  TO_ADMIN_PENDING_COMPANY_FILTERS: "to-admin-pending-company-filters",

  TO_ADMIN_RELATION_PAGINATION: "to-admin-relation-pagination",
  TO_ADMIN_RELATION_FILTERS: "to-admin-relation-filter",

  CREATE_USER_PAGE_USER_PAGINATION: "create-user-page-user-pagination",

  GROUP_PAGINATION: "group-pagination",
  GROUP_DETAILS: "group-details-pagination",
  GROUP_FILTERS: "group-filters",
  GROUP_COMPANY_LIST_PAGINATION: "group-company-list-pagination",
  GROUP_COMPANY_LIST_FILTERS: "group-company-list-filters",
});
