import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Icon, Graphic, Tooltip } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";

import { formatDate } from "shared/utils/dateUtils";

import { useExternalFilters } from "../../../../../shared/hooks/useSearchFilters";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "../../../../../shared/webStorage";
import { TraceoneAdminUserFilter } from "../../../../Users/containers/TraceoneAdminUserList/models";
import { GroupCompaniesColumn, companyGroupDetails } from "../../../models";
import styles from "../GeneralInformation/GeneralInformation.module.less";
import GroupActions from "../GeneralInformation/GroupActions";

export default function useDetailsTable({
  companies,
  mainCompanyId,
  companyGroupId,
}: {
  companies: companyGroupDetails[];
  mainCompanyId: string;
  companyGroupId: string;
}) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const activeCompaniesArray = companies?.filter(company => company?.isActive);

  const numberOfActiveCompanies = activeCompaniesArray?.length;

  const { handleExternalFilterChange } =
    useExternalFilters<TraceoneAdminUserFilter>({
      paginationWebStorage:
        TablePaginationStorage.TO_ADMIN_REGISTERED_USER_LIST,
      filterWebStorage: SearchFiltersStorage.TO_ADMIN_REGISTERED_USER_LIST,
    });

  const truncateCompanyName = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const columns: ColumnsType<GroupCompaniesColumn> = [
    {
      title: formatMessage({
        id: "companyList.companyDisplayName",
      }),
      dataIndex: "companyName",
      key: "companyName",
      width: 220,
      ellipsis: true,
      render: (companyName, record) => {
        return (
          <div className={styles.companyName}>
            <Typography.Link
              onClick={() => {
                history.push(`/companies/${record.key}`);
              }}
            >
              {truncateCompanyName(companyName, 40)}
            </Typography.Link>
            {record?.key === mainCompanyId && (
              <Tooltip
                text={formatMessage({ id: "groupList.table.mainCompany" })}
              >
                <Graphic name="main-company" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: formatMessage({
        id: "general.addedOn",
      }),
      dataIndex: "addedOn",
      key: "addedOn",
      width: 120,
      ellipsis: true,
      render: addedOn => formatDate(addedOn, "DD/MM/YYYY"),
    },
    {
      title: formatMessage({
        id: "general.addedBy",
      }),
      dataIndex: "addedBy",
      key: "addedBy",
      width: 120,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.isMainContact",
      }),
      dataIndex: "mainContact",
      key: "mainContact",
      width: 120,
      ellipsis: true,
      render: (mainContact, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/users/access/${record.mainContactId}`);
            }}
          >
            {mainContact}
          </Typography.Link>
        );
      },
    },
    {
      title: formatMessage({
        id: "general.users",
      }),
      dataIndex: "users",
      key: "users",
      width: 60,
      ellipsis: true,
      render: (users, record) => {
        return (
          <Typography.Link
            onClick={() => {
              const owningCompanyId = record?.key;
              handleExternalFilterChange({ owningCompanyId });
              history.push(`/users/list`);
            }}
          >
            {users}
          </Typography.Link>
        );
      },
    },
    {
      title: (
        <Icon style={{ margin: "auto" }} name="settings" color="primary" />
      ),
      dataIndex: "actions",
      key: "actions",
      width: 40,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <>
          <GroupActions
            data={record}
            companyGroupId={companyGroupId}
            numberOfActiveCompanies={numberOfActiveCompanies}
          />
        </>
      ),
    },
  ];

  const data = companies?.map(company => {
    const result: GroupCompaniesColumn = {
      key: company.companyId,
      companyName: company.companyDisplayName,
      legalName: company.companyLegalName,
      dunsCode: company.companyDuns,
      addedOn: company.addedAt,
      addedBy: company.addedBy,
      users: company.userCount,
      mainContact: company.mainContactUserName,
      applicationTypeIds: company.applicationTypeIds,
      mainContactId: company.mainContactUserId,
      isActive: company.isActive,
    };

    return result;
  });

  return {
    columns,
    data,
  };
}
