export enum ModernizedApplicationType {
  PROJECT = 50,
  QMS = 51,
  SPEC = 70,
  RFX = 80,
  MASTERDATA = 100,
  INSIGHT = 110,
  ADMINISTRATION = 90,
}

export enum ApplicationType {
  QUALITY_PORTAL = 0,
  PACK_PORTAL = 1,
  PDM = 8,
  PDM_NA = 22,
  PDM_FIND = 23,
  BUSINESS_INTELLIGENCE = 9,
  TON = 40,
  PROJECT = ModernizedApplicationType.PROJECT,
  QMS = ModernizedApplicationType.QMS,
  SPEC = ModernizedApplicationType.SPEC,
  RFX = ModernizedApplicationType.RFX,
  MASTERDATA = ModernizedApplicationType.MASTERDATA,
  INSIGHT = ModernizedApplicationType.INSIGHT,
  ADMINISTRATION = ModernizedApplicationType.ADMINISTRATION,
}

export const APPLICATIONS = [
  {
    id: 0,
    name: "Quality Portal",
    value: "Quality Portal",
    graphic: "qp",
  },
  {
    id: 1,
    name: "Pack Portal",
    value: "Pack Portal",
    graphic: "pp",
  },
  {
    id: 8,
    name: "PDM",
    value: "PDM",
    graphic: "pdm",
  },
  {
    id: 9,
    name: "Business Intelligence",
    value: "Business Intelligence",
    graphic: "bi",
  },
  {
    id: 110,
    name: "Insight",
    value: "Insight",
    graphic: "insight",
  },
  {
    id: 22,
    name: "PDM NA",
    value: "PDM NA",
    graphic: "pdm_na",
  },
  {
    id: 23,
    name: "PDM FIND",
    value: "PDM FIND",
    graphic: "pdm_ms",
  },
  {
    id: 40,
    name: "Network",
    value: "Ton",
    graphic: "ton",
  },
  {
    id: 50,
    name: "Project",
    value: "Project",
    graphic: "project",
  },
  {
    id: 51,
    name: "QMS",
    value: "QMS",
    graphic: "qms",
  },
  {
    id: 70,
    name: "Specification",
    value: "Spec",
    graphic: "spec",
  },
  {
    id: 80,
    name: "Rfx",
    value: "Rfx",
    graphic: "rfx",
  },
  {
    id: 90,
    name: "Administration",
    value: "Administration",
    graphic: "admin",
  },
  {
    id: 100,
    name: "Master Data",
    value: "MD",
    graphic: "md",
  },
];

export enum UserStatus {
  ENABLED = "Enabled",
  SUSPENDED = "Suspended",
  DISABLED = "Disabled",
  DEACTIVATED = "Deactivated",
  LOCKED = "Locked",
  PENDING = "Pending",
  REJECTED = "Rejected",
}

export enum UserActionOptions {
  SUSPEND = "Suspend",
  DELIST = "Delist",
}

export enum SubscriptionStatus {
  ACTIVE = "Active",
  INACTIVE = "InActive",
}

export enum RelationStatus {
  ACTIVE = "Active",
  INACTIVE = "InActive",
}

export enum ContractStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum RelationType {
  ANIMATOR = "Animator",
  CONTRIBUTOR = "Contributor",
}

export enum CompanyStatus {
  ENABLED = "Enabled",
  SUSPENDED = "Suspended",
  DISABLED = "Disabled",
  PENDING = "Pending",
  REJECTED = "Rejected",
  VALIDATED = "Done",
  DISABLED_SUSPENDED = "DisabledAndSuspended",
}

export enum CompanyActivity {
  RETAILER = 1,
  SUPPLIER = 2,
}

export enum TeamMemberResponsibilityParentItem {
  RETAILER = "1243372f-3ab8-404e-9684-590d90dead90",
  SUPPLIER = "60dc67f0-7b2c-4b44-b2c2-291fe2cabd0b",
}

export enum OfferType {
  DEFAULT = "Default",
  ADD_ON = "AddOn",
}

export enum ElectronicSignatureType {
  NO = "No",
  BASIC = "Basic",
  ADVANCED = "Advanced",
}

export enum CompanyCountry {
  CANADA = "CA",
  UNITEDSTATES = "US",
}

export enum IdentifiersType {
  SIRET = "SIRET",
  VAT = "VAT",
  GLN = "GLN",
}

export enum IdentifiersItemId {
  SIRET_ITEM_ID = "c72de667-9229-43ef-bf82-368915a061f9",
  VAT_ITEM_ID = "234277f6-0501-4148-bb74-935a08c055ac",
}

export enum GroupModalMode {
  CREATE_GROUP = "Creat",
  ADD_TO_GROUP = "Add",
}

export const GlobalCategoryId = "00000000-0000-0000-0000-000000000000";
export const Titles = ["Dr", "Miss", "Mr", "Mrs", "Ms", "Prof", "Sir"];

export const showNewSearchForm = true;
