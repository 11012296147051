import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Icon } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";

import { formatDate } from "shared/utils/dateUtils";

import { GroupTableColumn } from "../../../models";
import GroupListActions from "../GroupListActions/GroupListActions";

export default function useGroupsTable(
  groups,
  setIsUpdateGroupModalOpen,
  setExistingCompanyGroupId,
  setExistingGroupName,
  setExistingGroupCode
) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const columns: ColumnsType<GroupTableColumn> = [
    {
      title: formatMessage({
        id: "groupList.table.groupName",
      }),
      dataIndex: "groupName",
      key: "groupName",
      width: 160,
      ellipsis: true,
      render: (groupName, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/groups/details/${record.key}`);
            }}
          >
            {groupName}
          </Typography.Link>
        );
      },
    },
    {
      title: formatMessage({
        id: "groupList.table.groupCode",
      }),
      dataIndex: "groupCode",
      key: "groupCode",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "groupList.table.mainCompany",
      }),
      dataIndex: "mainCompany",
      key: "mainCompany",
      width: 200,
      ellipsis: true,
      render: (mainCompanyName, record) => {
        return (
          <Typography.Link
            onClick={() => {
              history.push(`/companies/${record.mainCompanyId}`);
            }}
          >
            {mainCompanyName}
          </Typography.Link>
        );
      },
    },

    {
      title: formatMessage({
        id: "groupList.table.numberOfCompanies",
      }),
      dataIndex: "numberOfCompanies",
      key: "numberOfCompanies",
      width: 120,
      ellipsis: true,
    },

    {
      title: formatMessage({
        id: "general.creationDate",
      }),
      dataIndex: "creationDate",
      key: "creationDate",
      width: 120,
      ellipsis: true,
      render: creationDate => formatDate(creationDate),
    },

    {
      title: (
        <Icon style={{ margin: "auto" }} name="settings" color="primary" />
      ),
      dataIndex: "actions",
      key: "actions",
      width: 40,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <>
          <GroupListActions
            companyGroupId={record?.rawData?.companyGroupId}
            setIsUpdateGroupModalOpen={setIsUpdateGroupModalOpen}
            groupName={record?.rawData?.groupName}
            groupCode={record?.rawData?.groupCode}
            setExistingCompanyGroupId={setExistingCompanyGroupId}
            setExistingGroupName={setExistingGroupName}
            setExistingGroupCode={setExistingGroupCode}
          />
        </>
      ),
    },
  ];

  const data = groups?.map(group => {
    const activeCompaniesCount =
      group.companies?.filter(company => company.isActive).length || 0;

    const result: GroupTableColumn = {
      key: group.companyGroupId,
      groupName: group.groupName,
      groupCode: group.groupCode,
      mainCompany: group.mainCompanyName,
      creationDate: group.createdAt,
      mainCompanyId: group.mainCompanyId,
      numberOfCompanies: activeCompaniesCount,
      rawData: group,
    };

    return result;
  });

  return {
    columns,
    data,
  };
}
