import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI } from "apis";

import { ApplicationType, CompanyStatus } from "shared/constants";

export const fetchGroupCompanies = createAsyncThunk(
  "companyList/fetchGroupCompanies",
  async ({
    companyIds,
    applicationTypeIds,
    companyActivityId,
    companyStatuses,
    creationDate,
    searchText,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    fromTargetSuspendDate,
    toTargetSuspendDate,
    companyCountry,
    skip,
    take,
  }: {
    companyIds?: string[];
    applicationTypeIds?: ApplicationType[];
    companyActivityId?: number;
    companyStatuses?: CompanyStatus[];
    creationDate?: string;
    searchText?: string;
    fromStatusUpdatedDate?: string;
    toStatusUpdatedDate?: string;
    fromTargetSuspendDate?: string;
    toTargetSuspendDate?: string;
    companyCountry?: string;
    skip: number;
    take: number;
  }) => {
    const { data } = await CumdAPI.getCompaniesByFiltersForToAdmin(
      { companyIds, applicationTypeIds, companyStatuses },
      {
        companyActivityId,
        creationDate,
        fromStatusUpdatedDate,
        toStatusUpdatedDate,
        fromTargetSuspendDate,
        toTargetSuspendDate,
        companyCountry,
        searchText,
        skip,
        take,
      }
    );
    return data;
  }
);
