import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import { Button } from "@trace-one/design-system";

import { CompanyForTraceoneAdminData, SubscriptionData } from "models";

import Table from "components/Table";
import { CompanyStatus } from "shared/constants";

import { CumdAPI } from "../../../../apis";
import usePermissions from "../../../../core/oidc/usePermissions";
import useToast from "../../../../shared/hooks/useToast";

import styles from "./CompanySubscriptionList.module.less";
import useCompanySharedSubscriptionTable from "./hooks/useCompanySharedSubscritionTable";
import useCompanySubscriptionTable from "./hooks/useCompanySubscriptionTable";
import SubscriptionTableHeader from "./SubscriptionTableHeader";

interface CompanySubscriptionListProps {
  company: CompanyForTraceoneAdminData;
  companySubscriptions: SubscriptionData[];
  refetchData?: () => void;
  isLoading?: boolean;
}

const CompanySubscriptionList: React.FC<CompanySubscriptionListProps> = ({
  company,
  companySubscriptions,
  refetchData,
  isLoading,
}) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const history = useHistory();
  const toast = useToast();

  const { formatMessage } = useIntl();

  const [currentTab, setCurrentTab] = useState<string>("company-subscriptions");
  const [sharedSubscriptions, setSharedSubscriptions] = useState<any>();

  const [sharedCompaniesLength, setSharedCompaniesLength] = useState<any>();

  const fetchSharedSubscriptions = async () => {
    if (companySubscriptions?.length !== 0) {
      try {
        const { data } = await CumdAPI.getSharedSubscriptionById({
          ownerCompanyId: company?.companyId,
        });

        setSharedSubscriptions(data);

        const totalSharedCompaniesLength = data?.reduce(
          (acc, subscription) =>
            acc + (subscription?.sharedCompanies?.length || 0),
          0
        );
        setSharedCompaniesLength(totalSharedCompaniesLength);
      } catch (error) {
        toast.saveError({ error });
      }
    } else return;
  };

  useEffect(() => {
    if (company?.companyGroupId !== null) {
      fetchSharedSubscriptions();
    }
  }, [company?.companyId, company?.companyGroupId]);

  const { columns, data } = useCompanySubscriptionTable({
    companyId: company.companyId,
    companyGroupId: company?.companyGroupId,
    companySubscriptions,
    refetchData,
    setSharedCompaniesLength,
  });

  useEffect(() => {
    if (sharedCompaniesLength === 0) {
      setCurrentTab("company-subscriptions");
    }
  }, [sharedCompaniesLength]);

  const sharedCompanies = sharedSubscriptions?.flatMap(
    companies => companies.sharedCompanies
  );

  const { sharedColumns, sharedData } = useCompanySharedSubscriptionTable({
    companyId: company.companyId,
    companyGroupId: company?.companyGroupId,
    sharedSubscriptions,
    refetchData,
    company,
    fetchSharedSubscriptions,
  });

  const companyIsPartOfGroup = company?.companyGroupId !== null;
  return (
    <>
      <div className={styles.actions}>
        {(hasRoleSuperAdmin || hasRoleAdministrator) &&
          company.companyStatus === CompanyStatus.ENABLED && (
            <Button
              onClick={() => {
                history.push(
                  `/companies/${company.companyId}/subscriptions/create`
                );
              }}
              data-test-id="ad-subscription-create"
            >
              {formatMessage({
                id: "general.createSubscription",
              })}
            </Button>
          )}
      </div>

      <SubscriptionTableHeader
        listingResult={
          currentTab === "company-subscriptions"
            ? formatMessage(
                {
                  id: "companySubscriptionList.tableHeader.listingResultNumber",
                },
                {
                  current: companySubscriptions.length,
                }
              )
            : formatMessage(
                {
                  id: "companySharedSubscriptionList.tableHeader.listingResultNumber",
                },
                {
                  current: sharedCompanies?.length,
                  total: sharedCompanies?.length,
                }
              )
        }
        companyGroupId={company?.companyGroupId}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        companyIsPartOfGroup={companyIsPartOfGroup}
        hasSharedSubscriptions={sharedCompaniesLength !== 0}
        fetchSharedSubscriptions={fetchSharedSubscriptions}
      />

      {currentTab === "company-subscriptions" && (
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          hidePagination
        />
      )}

      {currentTab === "group-subscriptions" && (
        <Table
          columns={sharedColumns}
          dataSource={sharedData}
          loading={isLoading}
          hidePagination
        />
      )}
    </>
  );
};
export default CompanySubscriptionList;
