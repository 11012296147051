import React from "react";
import { useIntl } from "react-intl";

import { CompanyForTraceoneAdmin } from "@trace-one/api-clients.cumd";
import { Basket as BCBasket } from "@trace-one/business-components";

import { GroupModalMode } from "../../../../shared/constants";

export interface BasketProps {
  mode?: string;
  companiesInBasket?: CompanyForTraceoneAdmin[];
  setCompaniesInBasket?: any;
  createGroup?: any;
}
const Basket: React.FC<BasketProps> = ({
  mode,
  companiesInBasket,
  setCompaniesInBasket,
  createGroup,
}) => {
  const { formatMessage } = useIntl();

  const handleDelete = (companyId: string) => {
    setCompaniesInBasket(
      companiesInBasket?.filter(company => company?.companyId !== companyId)
    );
  };

  return (
    <div data-test-id="group-modal-basket">
      <BCBasket
        title={formatMessage({ id: "general.yourSelection" })}
        items={companiesInBasket?.map(company => ({
          id: company.companyId,
          text: company.companyDisplayName,
        }))}
        emptyNote={
          mode === GroupModalMode.CREATE_GROUP
            ? formatMessage({ id: "groupCreate.basket.noResult.text" })
            : formatMessage({ id: "groupAddToGroup.basket.noResult.text" })
        }
        validateButtonText={formatMessage({
          id: "general.validate",
        }).toUpperCase()}
        onDelete={company => handleDelete(company.id)}
        onValidate={() => createGroup()}
        validateButtonDisabled={
          mode === GroupModalMode.CREATE_GROUP && companiesInBasket?.length < 2
        }
      />
    </div>
  );
};

export default Basket;
