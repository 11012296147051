import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { selectTeamMemberResponsibilitiesData } from "reduxStore/shared/selectors";
import { fetchUsersForCompanyAdmin } from "reduxStore/userList/asyncActions";
import {
  selectIsUsersLoading,
  selectUsers,
  selectUsersSkipAndTakeQueryStats,
} from "reduxStore/userList/selectors";
import { clearUsers } from "reduxStore/userList/slice";

import Table from "components/Table";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import { RlmdAPI } from "../../../../apis";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { showNewSearchForm, UserStatus } from "../../../../shared/constants";
import useToast from "../../../../shared/hooks/useToast";

import CompanyAdminUserTableHeader from "./CompanyAdminUserTableHeader";
import useUserTable from "./hooks/useUserTable";
import { CompanyAdminUserFilter } from "./models";
import UserSearch from "./OldUserSearch";
import NewUserSearch from "./UserSearch";

const CompanyAdminUserList = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const languageCode = useSelector(selectUserLanguageCode);

  const users = useSelector(selectUsers);
  const skipAndTakeQueryStats = useSelector(selectUsersSkipAndTakeQueryStats);

  const [responsibilitiesData, setResponsabilitiesData] = useState<any>();

  const isUserTableLoading = useSelector(selectIsUsersLoading);
  const isTeamMemberResponsiblitiesLoading = useSelector(
    selectTeamMemberResponsibilitiesData
  ).isLoading;
  const isLoading = isUserTableLoading || isTeamMemberResponsiblitiesLoading;

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats,
      webStorage: TablePaginationStorage.COMPANY_ADMIN_USER_LIST,
    });
  const {
    searchText,
    setSearchText,
    filterObj,
    mergeFilters,
    removeAllFiltersAndResetPageNumber,
  } = useSearchFilters<CompanyAdminUserFilter>(
    {
      userStatuses: [UserStatus.ENABLED],
      userJobTitle: undefined,
      responsibilityId: undefined,
      applicationTypeIds: [],
      administratorFilterList: [],
    },
    {
      clearedFilters: {
        userStatuses: [],
        userJobTitle: undefined,
        responsibilityId: undefined,
        applicationTypeIds: [],
        administratorFilterList: [],
      },
      resetPageNumber,
      webStorage: SearchFiltersStorage.COMPANY_ADMIN_USER_LIST,
    }
  );

  const refetchUsers = useCallback(() => {
    const {
      userStatuses,
      userJobTitle,
      responsibilityId,
      applicationTypeIds,
      administratorFilterList,
      userLockedFilter,
    } = filterObj;
    dispatch(
      fetchUsersForCompanyAdmin({
        userStatuses,
        userJobTitle: userJobTitle ? Number(userJobTitle) : undefined,
        responsibilityId,
        applicationTypeIds: applicationTypeIds?.map(applicationTypeId =>
          Number(applicationTypeId)
        ),
        isAccountAdministrator:
          administratorFilterList.length === 1
            ? administratorFilterList[0] === "yes"
            : undefined,
        isLocked:
          userLockedFilter?.length === 1
            ? userLockedFilter[0] === "yes"
            : undefined,
        searchText,
        skip: paginationQuery.skip,
        take: paginationQuery.take,
      })
    );
  }, [searchText, filterObj, paginationQuery]);

  useEffect(() => {
    return () => {
      dispatch(clearUsers(null));
    };
  }, []);

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers]);

  const owningCompanyId = users?.[0]?.owningCompanyId;
  const fetchResponsabilities = async () => {
    try {
      const { data: responsabilities } =
        await RlmdAPI.getReferenceListItemsByReferenceListName(
          "Team_Member_Responsibility",
          {
            languageCode,
            companyId: owningCompanyId,
          }
        );

      setResponsabilitiesData(responsabilities);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    fetchResponsabilities();
  }, [owningCompanyId]);

  const { columns, data } = useUserTable({
    users,
    refetchUsers,
    responsibilitiesData,
  });

  return (
    <>
      {showNewSearchForm ? (
        <NewUserSearch
          filterObj={filterObj}
          mergeFilters={mergeFilters}
          initialSearchValue={searchText}
          onSearch={setSearchText}
          onClearFiltersClick={removeAllFiltersAndResetPageNumber}
        />
      ) : (
        <UserSearch
          filterObj={filterObj}
          mergeFilters={mergeFilters}
          initialSearchValue={searchText}
          onSearch={setSearchText}
          onClearFiltersClick={removeAllFiltersAndResetPageNumber}
        />
      )}

      <CompanyAdminUserTableHeader
        totalCount={skipAndTakeQueryStats.totalCount ?? 0}
        currentNumber={skipAndTakeQueryStats.currentCount ?? 0}
      />
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />
    </>
  );
};

export default CompanyAdminUserList;
