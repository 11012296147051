import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { toaster } from "@trace-one/design-system";
import { ModalService, ConfirmationModal } from "@trace-one/react-components";
import { Menu } from "antd";

import { UserForUIAdminData } from "models";

import ActionDropdown from "components/ActionDropdown";
import { UserStatus } from "shared/constants";

import { CumdAPI } from "../../../../apis";
import MainContactModal from "../../../../components/MainContactModal";
import usePermissions from "../../../../core/oidc/usePermissions";
import { selectUserLanguageCode } from "../../../../reduxStore/user/selectors";
import { ErrorCode, hasErrorOccurred } from "../../../../shared/errors";
import useToast from "../../../../shared/hooks/useToast";
import styles from "../TraceoneAdminUserList/TraceoneAdminUserList.module.less";

interface UserActionProps {
  data: UserForUIAdminData;
  users: UserForUIAdminData[];
  refetchUsers: () => void;
}

const UserAction: React.FC<UserActionProps> = ({
  data,
  users,
  refetchUsers,
}) => {
  const languageCode = useSelector(selectUserLanguageCode);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const toast = useToast();
  const { isCompanyAdmin, isTraceoneAdmin } = usePermissions();

  const isLocked = data.userLocked;

  const [isMainContactModalVisible, setIsMainContactModalVisible] =
    useState<boolean>(false);
  const [previousMainContact, setPreviousMainContact] = useState<any>();

  const handleUpdateMainContact = async () => {
    try {
      await CumdAPI.updateUserMainContact(data?.userId);

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "toast.confirmation.savingDescription",
        }),
        type: "confirmation",
      });

      setIsMainContactModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  useEffect(() => {
    const mainContactUser = users?.find(user => user.isMainContact);

    if (mainContactUser) {
      setPreviousMainContact(mainContactUser);
    }
  }, [users]);

  const allowManageUserAccess = isCompanyAdmin && !isTraceoneAdmin;

  const handleSuspendedUser = async () => {
    try {
      await CumdAPI.activeUserStatusById(data.userId);
      refetchUsers();
    } catch (error) {
      toast.saveError({ error });
    }
  };
  const handleModalConfirm = async () => {
    try {
      await CumdAPI.suspendUserStatusById(data.userId);
      refetchUsers();
    } catch (error) {
      if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_TO,
        })
      ) {
        toast.error({
          error,
          description: formatMessage({
            id: "toast.error.cannot.deactivate.suspend.TO",
          }),
        });
      } else if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_COMPANY_ADMIN,
        })
      ) {
        toast.error({
          error,
          description: formatMessage({
            id: "toast.error.cannot.deactivate.suspend.company.admin",
          }),
        });
      } else if (
        hasErrorOccurred({
          error,
          errorCode: ErrorCode.CAN_NOT_DEACTIVATE_OR_SUSPEND_MAIN_CONTACT,
        })
      ) {
        toast.error({
          error,
          description: formatMessage({
            id: "toast.error.cannot.deactivate.suspend.main.contact",
          }),
        });
      } else {
        toast.saveError({ error });
      }
    }
  };
  const handleConfirmationModal = () => {
    ModalService.openModal({
      component: (
        <ConfirmationModal
          languageCode={languageCode}
          icon={"users"}
          onConfirm={() => handleModalConfirm()}
          confirmationModalTitle={formatMessage({
            id: "userActionModel.suspend.title",
          })}
        >
          <section className={styles.userStatusModal}>
            <div className={styles.description}>
              <p>
                {formatMessage(
                  { id: "userActionModel.suspend.description" },
                  {
                    firstName: data.userFirstName,
                    lastName: data.userLastName,
                  }
                )}
              </p>
            </div>
            <p className={styles.confirmationQuestion}>
              {formatMessage({
                id: "general.action.confirmation.text",
              })}
            </p>
          </section>
        </ConfirmationModal>
      ),
    });
  };

  const handleUserStatusLock = async () => {
    try {
      await CumdAPI.setUserStatusLock(data.userId);
      refetchUsers();
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return data.userStatus === UserStatus.ENABLED ? (
    <>
      <ActionDropdown
        data-test-id="ad-user-action"
        overlay={
          <Menu>
            {allowManageUserAccess && (
              <Menu.Item
                onClick={() => {
                  history.push(`/users/access/${data.userId}`);
                }}
                key="user-manage-access"
                data-test-id="ad-manage-access"
                style={{ fontSize: "16px", lineHeight: "24px" }}
                disabled={isLocked}
              >
                {formatMessage({
                  id: "userList.action.editUser",
                })}
              </Menu.Item>
            )}

            {data?.isMainContact === false && (
              <Menu.Item
                onClick={() => {
                  setIsMainContactModalVisible(true);
                }}
                key="update-main-contact"
                data-test-id="update-main-contact"
                style={{ fontSize: "16px", lineHeight: "24px" }}
                disabled={isLocked}
              >
                {formatMessage({ id: "general.defineMainContact" })}
              </Menu.Item>
            )}

            {data.isMainContact !== true && (
              <Menu.Item
                onClick={() => handleConfirmationModal()}
                key="suspend-user"
                data-test-id="suspend-company-admin-user"
                style={{ fontSize: "16px", lineHeight: "24px" }}
                disabled={isLocked}
              >
                {formatMessage({
                  id: "general.suspend",
                })}
              </Menu.Item>
            )}

            {isLocked && (
              <Menu.Item
                onClick={handleUserStatusLock}
                key="unlock-user"
                data-test-id="unlock-user"
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                {formatMessage({
                  id: "general.unlocked",
                })}
              </Menu.Item>
            )}
          </Menu>
        }
      />

      <MainContactModal
        isModalVisible={isMainContactModalVisible}
        setIsModalVisible={setIsMainContactModalVisible}
        userFirstName={data?.userFirstName}
        userLastName={data?.userLastName}
        previousMainContact={previousMainContact}
        updateMainContact={handleUpdateMainContact}
      />
    </>
  ) : (
    <>
      <ActionDropdown
        data-test-id="ad-user-action"
        overlay={
          <Menu>
            {(data?.userStatus === UserStatus.SUSPENDED ||
              data?.userStatus === UserStatus.DISABLED) && (
              <Menu.Item
                onClick={handleSuspendedUser}
                key="authorize-user"
                data-test-id="authorize-company-admin-user"
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                {formatMessage({
                  id: "general.authorize",
                })}
              </Menu.Item>
            )}
          </Menu>
        }
      />
      <MainContactModal
        isModalVisible={isMainContactModalVisible}
        setIsModalVisible={setIsMainContactModalVisible}
        userFirstName={data?.userFirstName}
        userLastName={data?.userLastName}
        previousMainContact={previousMainContact}
        updateMainContact={handleUpdateMainContact}
      />
    </>
  );
};

export default UserAction;
