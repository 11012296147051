import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { useAppDispatch } from "reduxStore";
import { fetchCompanies } from "reduxStore/companyList/asyncActions";
import {
  selectCompanies,
  selectCompaniesSkipAndTakeQueryStats,
  selectIsCompaniesLoading,
} from "reduxStore/companyList/selectors";
import { clearCompanies } from "reduxStore/companyList/slice";
import { selectCompanyActivitiesData } from "reduxStore/shared/selectors";

import Table from "components/Table";
import useSearchFilters from "shared/hooks/useSearchFilters";
import useTablePagination from "shared/hooks/useTablePagination";
import {
  SearchFiltersStorage,
  TablePaginationStorage,
} from "shared/webStorage";

import { CumdAPI } from "../../../../apis";
import IconExpand from "../../../../components/IconExpand";
import { CompanyStatus, showNewSearchForm } from "../../../../shared/constants";
import useToast from "../../../../shared/hooks/useToast";

import CompanySearch from "./CompanySearch";
import CompanyTableHeader from "./CompanyTableHeader";
import CompanyInfoRows from "./components/CompanyInfoRows/CompanyInfoRows";
import useCompanyTable from "./hooks/useCompanyTable";
import { CompanyFilter } from "./models";
import OldCompanySearch from "./OldCompanySearch";

const CompanyList = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const [show, setShow] = useState<string>("registeredCompanies");
  const companies = useSelector(selectCompanies);
  const skipAndTakeQueryStats = useSelector(
    selectCompaniesSkipAndTakeQueryStats
  );

  const isCompaniesLoading = useSelector(selectIsCompaniesLoading);
  const isCompaniesActivitiesLoading = useSelector(
    selectCompanyActivitiesData
  ).isLoading;
  const isLoading = isCompaniesLoading || isCompaniesActivitiesLoading;

  const searchParams = new URLSearchParams(window.location.search);
  let companyIdFromUrl = searchParams.get("id");

  const { paginationQuery, setPaginationQuery, resetPageNumber } =
    useTablePagination({
      skipAndTakeQueryStats:
        show === "registeredCompanies" && skipAndTakeQueryStats,
      webStorage:
        show === "registeredCompanies" &&
        TablePaginationStorage.TO_ADMIN_REGISTERED_COMPANY_LIST,
    });

  let {
    searchText: registeredSearchText,
    setSearchText: registeredSetSearchText,
    mergeFilters: registeredMergeFilters,
    filterObj: registeredFilterObj,
    removeAllFiltersAndResetPageNumber:
      registeredRemoveAllFiltersAndResetPageNumber,
  } = useSearchFilters<CompanyFilter>(
    {
      applicationTypeIds: [],
      companyActivityId: undefined,
      companyStatuses: [CompanyStatus.ENABLED],
      createdAt: undefined,
      fromStatusUpdatedDate: undefined,
      toStatusUpdatedDate: undefined,
      fromTargetSuspendDate: undefined,
      toTargetSuspendDate: undefined,
    },
    {
      clearedFilters: {
        applicationTypeIds: [],
        companyActivityId: undefined,
        companyStatuses: [],
        creationDate: undefined,
        fromStatusUpdatedDate: undefined,
        toStatusUpdatedDate: undefined,
        fromTargetSuspendDate: undefined,
        toTargetSuspendDate: undefined,
      },
      resetPageNumber,
      webStorage: SearchFiltersStorage.TO_ADMIN_REGISTERED_COMPANY_LIST,
    }
  );

  if (companyIdFromUrl) {
    registeredSearchText = companyIdFromUrl;
    history.push(window.location.pathname);
  }

  let filterObj = show === "registeredCompanies" && registeredFilterObj;
  const {
    companyActivityId,
    applicationTypeIds,
    companyStatuses,
    createdAt,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    fromTargetSuspendDate,
    toTargetSuspendDate,
  } = filterObj;

  const refetchCompanies = useCallback(() => {
    const companyActivityIdNumber = Number(companyActivityId);

    const _companyStatuses = companyStatuses.flatMap(status => {
      if (status === CompanyStatus.DISABLED_SUSPENDED) {
        return [CompanyStatus.DISABLED, CompanyStatus.SUSPENDED];
      } else {
        return status;
      }
    });

    show === "registeredCompanies" &&
      dispatch(
        fetchCompanies({
          companyIds: companyIdFromUrl ? [companyIdFromUrl] : null,
          companyActivityId: companyActivityId
            ? Number(companyActivityIdNumber)
            : undefined,
          applicationTypeIds: applicationTypeIds?.map(applicationTypeId =>
            Number(applicationTypeId)
          ),
          companyStatuses: _companyStatuses,
          creationDate:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          searchText: registeredSearchText,
          fromStatusUpdatedDate:
            fromStatusUpdatedDate &&
            moment(fromStatusUpdatedDate).format("YYYY-MM-DD"),
          toStatusUpdatedDate:
            toStatusUpdatedDate &&
            moment(toStatusUpdatedDate).format("YYYY-MM-DD"),
          fromTargetSuspendDate:
            fromTargetSuspendDate &&
            moment(fromTargetSuspendDate).format("YYYY-MM-DD"),
          toTargetSuspendDate:
            toTargetSuspendDate &&
            moment(toTargetSuspendDate).format("YYYY-MM-DD"),
          skip: paginationQuery.skip,
          take: paginationQuery.take,
        })
      );
  }, [
    registeredSearchText,
    paginationQuery,
    companyStatuses,
    applicationTypeIds,
    fromStatusUpdatedDate,
    toStatusUpdatedDate,
    fromTargetSuspendDate,
    toTargetSuspendDate,
    show,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearCompanies(null));
    };
  }, []);

  useEffect(() => {
    refetchCompanies();
  }, [refetchCompanies]);

  const { columns, data, rowClassName } = useCompanyTable({
    companies,
    refetchCompanies,
  });
  const handleExport = async () => {
    const _companyStatuses = companyStatuses.flatMap(status => {
      if (status === CompanyStatus.DISABLED_SUSPENDED) {
        return [CompanyStatus.DISABLED, CompanyStatus.SUSPENDED];
      } else {
        return status;
      }
    });

    const companyActivityIdNumber = Number(companyActivityId);

    try {
      await CumdAPI.exportCompaniesInBulk(
        {
          companyStatuses: _companyStatuses,
          applicationTypeIds: applicationTypeIds.map(applicationTypeId =>
            Number(applicationTypeId)
          ),
        },
        {
          searchText: registeredSearchText,
          companyActivityId: companyActivityId
            ? Number(companyActivityIdNumber)
            : undefined,
          creationDate:
            createdAt && moment(createdAt, "DD/MM/YYYY").format("YYYY-MM-DD"),
          fromStatusUpdatedDate:
            fromStatusUpdatedDate &&
            moment(fromStatusUpdatedDate).format("YYYY-MM-DD"),
          toStatusUpdatedDate:
            toStatusUpdatedDate &&
            moment(toStatusUpdatedDate).format("YYYY-MM-DD"),
          fromTargetSuspendDate:
            fromTargetSuspendDate &&
            moment(fromTargetSuspendDate).format("YYYY-MM-DD"),
          toTargetSuspendDate:
            toTargetSuspendDate &&
            moment(toTargetSuspendDate).format("YYYY-MM-DD"),
        }
      );

      toast.success({
        description: formatMessage({ id: "general.export.message" }),
      });
    } catch (error) {
      toast.saveError({ error });
    }
  };

  return (
    <>
      {showNewSearchForm ? (
        <CompanySearch
          filterObj={filterObj}
          mergeFilters={registeredMergeFilters}
          initialSearchValue={registeredSearchText}
          onSearch={registeredSetSearchText}
          onClearFiltersClick={registeredRemoveAllFiltersAndResetPageNumber}
        />
      ) : (
        <OldCompanySearch
          filterObj={filterObj}
          mergeFilters={registeredMergeFilters}
          initialSearchValue={registeredSearchText}
          onSearch={registeredSetSearchText}
          onClearFiltersClick={registeredRemoveAllFiltersAndResetPageNumber}
        />
      )}

      <CompanyTableHeader
        showed={show}
        setChangeShow={setShow}
        totalCount={
          show === "registeredCompanies" && skipAndTakeQueryStats.totalCount
        }
        currentNumber={skipAndTakeQueryStats.currentCount ?? 0}
        handleExport={handleExport}
      />

      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandIcon: props => <IconExpand {...props} />,
          expandedRowRender: record => (
            <CompanyInfoRows record={record.rawData} />
          ),
        }}
        rowClassName={rowClassName}
        loading={isLoading}
        skip={paginationQuery.skip}
        take={paginationQuery.take}
        skipAndTakeQueryStats={skipAndTakeQueryStats}
        setPaginationQuery={setPaginationQuery}
      />
    </>
  );
};

export default CompanyList;
