import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Select, Input, Heading, Button } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Form } from "antd";
import { v4 as uuid } from "uuid";

import { CatalogueAPI } from "../../../../../apis";
import ProductCategoryTreeSelect from "../../../../../components/ProductCategoryTreeSelect";
import { useAppDispatch } from "../../../../../reduxStore";
import { fetchCompanyActivities } from "../../../../../reduxStore/shared/asyncActions";
import { selectCompanyActivitiesData } from "../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../reduxStore/user/selectors";

import IdentificationCode from "./IdentificationCode";
import styles from "./TestCompanyInformation.module.less";

interface TestCompanyInformationProps {
  setStepIndex: (step: number) => void;
  setTestCompanyId?: any;
  isTestCompanyCardSelected?: boolean;
  testCompanyName?: string;
  setTestCompanyName?: (string) => void;
  countryOptions?: object[];
  testCountry?: string;
  setTestCountry?: (string) => void;
  countryDivision?: string;
  setCountryDivision?: (string) => void;
  testCity?: string;
  setTestCity?: (string) => void;
  testPostalCode?: string;
  setTestPostalCode?: (string) => void;
  testAddress?: string;
  setTestAddress?: (string) => void;
  identifiers?: { id: string; value: string }[];
  setIdentifiers?: any;
  testCompanyActivity?: string;
  setTestCompanyActivity?: (string) => void;
  testCategory?: { id: string; categoryListId: string; name: string }[];
  // eslint-disable-next-line no-empty-pattern
  setTestCategory?: ([]) => void;
  setTestCompanyValue?: (any) => void;
}

const TestCompanyInformation: React.FC<TestCompanyInformationProps> = ({
  setStepIndex,
  setTestCompanyId,
  isTestCompanyCardSelected,
  testCompanyName,
  setTestCompanyName,
  countryOptions,
  testCountry,
  setTestCountry,
  countryDivision,
  setCountryDivision,
  testCity,
  setTestCity,
  testPostalCode,
  setTestPostalCode,
  testAddress,
  setTestAddress,
  identifiers,
  setIdentifiers,
  testCompanyActivity,
  setTestCompanyActivity,
  testCategory,
  setTestCategory,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const [form] = Form.useForm();

  const [countryDivisions, setCountryDivisions] = useState<any>();

  const companiesActivitiesOptions = useSelector(
    selectCompanyActivitiesData
  ).data.map(({ itemCode, text }) => ({ value: itemCode, label: text }));

  useEffect(() => {
    dispatch(
      fetchCompanyActivities({
        languageCode,
      })
    );
  }, []);

  const getCountryDivisions = async country => {
    if (country === "CA" || country === "US") {
      return await CatalogueAPI.filterCountryDivision({
        countryCode: country,
      }).then(({ data }) => {
        setCountryDivisions(data);
      });
    }
  };

  const divisionsOptions = countryDivisions?.map(division => ({
    name: division.name,
    value: division.code,
  }));

  const sortedDivisionsOptions = divisionsOptions?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const isDisabled = () => {
    if (
      !testCountry ||
      !testCompanyName ||
      !testCity ||
      !testAddress ||
      !testPostalCode ||
      testCategory.length === 0 ||
      !testCompanyActivity
    ) {
      return true;
    }

    return (testCountry === "US" || testCountry === "CA") && !countryDivision;
  };

  useEffect(() => {
    getCountryDivisions(testCountry);
  }, [testCountry]);

  const handleCountryChange = value => {
    setTestCountry(value);
    setCountryDivision(null);
    form.setFieldsValue({
      state: null,
    });
  };

  return (
    <div className={styles.companyInfoSection}>
      <Heading size="xs">
        {formatMessage({ id: "companyCreate.companyInfo.title" })}
      </Heading>
      <Form name="basic" form={form}>
        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.companyName" })} required>
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                onChange={e => {
                  setTestCompanyName(e.target.value);
                  setTestCompanyId(uuid());
                }}
                defaultValue={testCompanyName}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.address" })} required>
            <Form.Item name="address">
              <Input
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                onChange={e => {
                  setTestAddress(e.target.value);
                }}
                defaultValue={testAddress}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.innerItemWrapper}>
          <div>
            <Label title={formatMessage({ id: "general.city" })} required>
              <Form.Item name="city">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setTestCity(e.target.value);
                  }}
                  defaultValue={testCity}
                  maxLength={255}
                />
              </Form.Item>
            </Label>
          </div>
          <div>
            <Label title={formatMessage({ id: "general.zipcode" })} required>
              <Form.Item name="zipCode">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setTestPostalCode(e.target.value);
                  }}
                  defaultValue={testPostalCode}
                />
              </Form.Item>
            </Label>
          </div>
        </div>
        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.country" })} required>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
            >
              <Select
                placeholder={formatMessage({ id: "general.selectValue" })}
                showSearch
                allowClear
                options={countryOptions}
                optionFilterProp="children"
                onChange={handleCountryChange}
                defaultValue={testCountry}
              />
            </Form.Item>
          </Label>
        </div>
        {(testCountry === "CA" || testCountry === "US") && (
          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.state" })} required>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "general.error.mandatory.field",
                    }),
                  },
                ]}
              >
                <Select
                  placeholder={formatMessage({ id: "general.selectValue" })}
                  showSearch
                  allowClear
                  options={sortedDivisionsOptions}
                  optionFilterProp="children"
                  onChange={setCountryDivision}
                  defaultValue={countryDivision}
                />
              </Form.Item>
            </Label>
          </div>
        )}

        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.identificationCode" })}>
            <Form.Item name="IdentificationCode">
              <IdentificationCode
                setIdentifiers={setIdentifiers}
                identifiers={identifiers}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({
              id: "general.activity",
            })}
            required
          >
            <Form.Item name="activity">
              <Select
                placeholder={formatMessage({
                  id: "general.selectValue",
                })}
                showSearch
                allowClear
                options={companiesActivitiesOptions}
                optionFilterProp="children"
                onChange={setTestCompanyActivity}
                defaultValue={testCompanyActivity}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({
              id: "companyGeneralInfo.productCategories",
            })}
            required
          >
            <Form.Item name="productCategories">
              <ProductCategoryTreeSelect
                category={testCategory}
                setCategory={setTestCategory}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            disabled={isDisabled()}
            onClick={() => {
              isTestCompanyCardSelected && setStepIndex(2);
            }}
            data-test-id="create-test-company-first-step-next-btn"
          >
            {formatMessage({ id: "general.next" })}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TestCompanyInformation;
