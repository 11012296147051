import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Select,
  Input,
  Heading,
  Button,
  Radio,
  toaster,
} from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Form } from "antd";

import { CumdAPI } from "../../../../../apis";
import usePermissions from "../../../../../core/oidc/usePermissions";
import { CompanyForTraceoneAdminData } from "../../../../../models";
import { useAppDispatch } from "../../../../../reduxStore";
import {
  fetchJobTitles,
  fetchLanguages,
} from "../../../../../reduxStore/shared/asyncActions";
import {
  selectJobTitlesData,
  selectLanguagesData,
} from "../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../reduxStore/user/selectors";
import { Titles } from "../../../../../shared/constants";
import { ErrorCode } from "../../../../../shared/errors";
import styles from "../CreateUserForm/CreateUserForm.module.less";

interface CreateUserFormProps {
  company: CompanyForTraceoneAdminData;
  users: any;
}

const CreateUserForm: React.FC<CreateUserFormProps> = ({ company }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const { isTraceoneAdmin } = usePermissions();

  const [title, setTitle] = useState(null);
  const [jobTitle, setJobTitle] = useState<number>(undefined);
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [language, setLanguage] = useState<string>("en-US");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [confirmationEmail, setConfirmationEmail] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(null);
  const [emailErrorCode, setEmailErrorCode] = useState<string>(null);
  const [isAdministrator, setIsAdministrator] = useState<boolean>(null);
  const [isMainContact, setIsMainContact] = useState<boolean>(null);

  const radioOptions = [
    { label: formatMessage({ id: "general.yes" }), value: true },
    { label: formatMessage({ id: "general.no" }), value: false },
  ];

  const onChangeIsAdministrator = e => {
    setIsAdministrator(e.target.value);
  };

  const onChangeIsMainContact = e => {
    setIsMainContact(e.target.value);
  };

  const titleOptions = Titles.map(title => ({
    value: title,
    label: title,
  }));

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  const languageOptions = useSelector(selectLanguagesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  useEffect(() => {
    dispatch(
      fetchJobTitles({
        languageCode,
      })
    );
    dispatch(
      fetchLanguages({
        languageCode,
      })
    );
  }, []);

  const validateEmail = email => {
    return CumdAPI.checkUserEmailAlreadyExists(email).then(({ data }) => {
      setEmailIsValid(data.isValid);
      setEmailErrorCode(data.errorCode);

      switch (data.errorCode) {
        case ErrorCode.EMAIL_ALREADY_EXISTS:
          isTraceoneAdmin
            ? toaster.open({
                message: formatMessage({
                  id: "companyCreate.firstUserInfo.toast.title",
                }),
                description: formatMessage({
                  id: "companyCreate.firstUserInfo.toast.description",
                }),
                type: "alert",
              })
            : toaster.open({
                message: formatMessage({
                  id: "toast.alert",
                }),
                description: formatMessage({
                  id: "companyCreate.firstUserInfo.toast.descriptionForCompanyAdmin",
                }),
                type: "alert",
              });
          break;
        case ErrorCode.EMAIL_INVALID:
          toaster.open({
            message: formatMessage({
              id: "toast.error.invalid.email.title",
            }),
            description: formatMessage({
              id: "toast.error.invalid.email.description",
            }),
            type: "alert",
          });
          break;
      }

      return data.isValid;
    });
  };

  const handleCreateUserAndAddMore = async () => {
    const emailIsValid = await validateEmail(email);

    if (!emailIsValid) {
      return;
    }

    try {
      await CumdAPI.createUser({
        owningCompanyId: company.companyId,
        userCivility: title,
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: email,
        userLanguagePreference: language,
        userJobTitle: Number(jobTitle),
        userPhoneNumber: phoneNumber,
        isAccountAdministrator: isAdministrator,
        isMainContact: isMainContact,
      });

      window.location.reload();
    } catch (error) {
      toaster.open({
        message: formatMessage({
          id: "general.error",
        }),
        description: formatMessage({
          id: "companyCreate.user.toast.save.error",
        }),
        type: "alert",
      });
    }
  };

  const handleCreateUserAndDone = async () => {
    const emailIsValid = await validateEmail(email);

    if (!emailIsValid) {
      return;
    }

    try {
      await CumdAPI.createUser({
        owningCompanyId: company.companyId,
        userCivility: title,
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: email,
        userLanguagePreference: language,
        userJobTitle: Number(jobTitle),
        userPhoneNumber: phoneNumber,
        isAccountAdministrator: isAdministrator,
        isMainContact: isMainContact,
      });

      isTraceoneAdmin
        ? history.push(`/companies/list?id=${company?.companyId}`)
        : history.push(`/users/list`);
    } catch (error) {
      toaster.open({
        message: formatMessage({
          id: "general.error",
        }),
        description: formatMessage({
          id: "companyCreate.user.toast.save.error",
        }),
        type: "alert",
      });
    }
  };

  return (
    <div className={styles.createUserSection}>
      <Heading size="xs">
        {formatMessage({ id: "companyCreate.firstUserInfo.title" })}
      </Heading>
      <Form name="basic">
        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.companyName" })}>
            <Form.Item name="companyName">
              <Input placeholder={company?.companyDisplayName} disabled />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.innerItemWrapper}>
          <div>
            <Label title={formatMessage({ id: "general.title" })} required>
              <Form.Item name="title">
                <Select
                  placeholder={formatMessage({
                    id: "general.selectValue",
                  })}
                  showSearch
                  allowClear
                  options={titleOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setTitle}
                  defaultValue={title}
                />
              </Form.Item>
            </Label>
          </div>
          <div>
            <Label title={formatMessage({ id: "general.jobTitle" })} required>
              <Form.Item name="jobTitle">
                <Select
                  placeholder={formatMessage({
                    id: "general.selectValue",
                  })}
                  showSearch
                  allowClear
                  options={jobTitlesOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setJobTitle}
                  defaultValue={jobTitle}
                />
              </Form.Item>
            </Label>
          </div>
        </div>

        <div className={styles.innerItemWrapper}>
          <div>
            <Label title={formatMessage({ id: "general.lastName" })} required>
              <Form.Item name="lastName">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                  defaultValue={lastName}
                />
              </Form.Item>
            </Label>
          </div>

          <div>
            <Label title={formatMessage({ id: "general.firstName" })} required>
              <Form.Item name="firstName">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                  defaultValue={firstName}
                />
              </Form.Item>
            </Label>
          </div>
        </div>

        <div className={styles.innerItemWrapper}>
          <div>
            <Label title={formatMessage({ id: "general.language" })} required>
              <Form.Item name="language">
                <Select
                  showSearch
                  allowClear
                  defaultValue={language}
                  options={languageOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setLanguage}
                />
              </Form.Item>
            </Label>
          </div>

          <div>
            <Label title={formatMessage({ id: "general.phone" })}>
              <Form.Item name="phoneNumber">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                  }}
                  type="number"
                  defaultValue={phoneNumber}
                />
              </Form.Item>
            </Label>
          </div>
        </div>

        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.email" })} required>
            <Form.Item
              name="email"
              validateStatus={emailIsValid === false && "error"}
              help={
                emailIsValid === false &&
                isTraceoneAdmin &&
                formatMessage({
                  id:
                    emailErrorCode === ErrorCode.EMAIL_ALREADY_EXISTS
                      ? "companyCreate.firstUserInfo.toast.description"
                      : "toast.error.invalid.email.description",
                })
              }
            >
              <Input
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                onChange={e => {
                  setEmail(e.target.value);
                  if (e.target.value === "") {
                    setEmailIsValid(null);
                  }
                }}
                defaultValue={email}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.confirmEmail" })} required>
            <Form.Item
              name="emailConfirmation"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "companyCreate.firstUserInfo.confirmationEmail.error.description",
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("email") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        formatMessage({
                          id: "companyCreate.firstUserInfo.confirmationEmail.error.description",
                        })
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                onChange={e => {
                  setConfirmationEmail(e.target.value);
                }}
                defaultValue={confirmationEmail}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({ id: "userCreate.administrator" })}
            required
          >
            <Form.Item name="administrator">
              <Radio.Group
                direction="horizontal"
                options={radioOptions}
                onChange={onChangeIsAdministrator}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({ id: "userCreate.mainContact" })}
            required
          >
            <Form.Item name="mainContact">
              <Radio.Group
                direction="horizontal"
                options={radioOptions}
                onChange={onChangeIsMainContact}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.btnWrapper}>
          <Button
            type="secondary"
            onClick={handleCreateUserAndAddMore}
            disabled={
              !title ||
              !jobTitle ||
              !lastName ||
              !firstName ||
              !email ||
              email !== confirmationEmail ||
              !confirmationEmail ||
              confirmationEmail !== email ||
              isMainContact === null ||
              isAdministrator === null ||
              !language
            }
            data-test-id="create-user-save-and-add-more-btn"
          >
            {formatMessage({ id: "companyCreate.firstUserInfo.saveAndAddBtn" })}
          </Button>
          <Button
            onClick={handleCreateUserAndDone}
            disabled={
              !title ||
              !jobTitle ||
              !lastName ||
              !firstName ||
              !email ||
              email !== confirmationEmail ||
              !confirmationEmail ||
              confirmationEmail !== email ||
              isMainContact === null ||
              isAdministrator === null ||
              !language
            }
            data-test-id="create-user-save-and-done-btn"
          >
            {formatMessage({
              id: "companyCreate.firstUserInfo.saveAndDoneBtn",
            })}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateUserForm;
