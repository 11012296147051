import { useIntl } from "react-intl";

import { DatePicker } from "@trace-one/react-components";
import moment from "moment";

import CheckableTags from "components/CheckableTags";
import SearchFilters, { SearchFiltersProps } from "components/SearchFilters";
import { CompanyStatus } from "shared/constants";

import useCompanyStatuses from "../../hooks/useCompanyStatuses";

import { CompanyFilter } from "./models";

interface PendingCompanySearchProps {
  filterObj: CompanyFilter;
  mergeFilters: (
    value:
      | Partial<CompanyFilter>
      | ((prevState: CompanyFilter) => CompanyFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: SearchFiltersProps["onSearch"];
  onClearFiltersClick: SearchFiltersProps["onClearFiltersClick"];
}

const CompanyPendingSearch: React.FC<PendingCompanySearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const { formatMessage } = useIntl();

  const { companyStatusLabels } = useCompanyStatuses();

  const getSelectedFilters = () => {
    const selectedFilters = [];

    if (filterObj.createdAt) {
      selectedFilters.push({
        value: filterObj.createdAt,
        name: filterObj.createdAt,
        onCloseClick: () => mergeFilters({ createdAt: undefined }),
      });
    }

    filterObj.companyStatuses?.forEach(option => {
      const label = companyStatusLabels[option];

      selectedFilters.push({
        value: label,
        name: label,
        onCloseClick: () =>
          mergeFilters(prev => ({
            ...prev,
            companyStatuses: prev.companyStatuses.filter(
              companyStatus => companyStatus !== option
            ),
          })),
      });
    });

    return selectedFilters;
  };

  return (
    <SearchFilters
      onSearch={onSearch}
      searchPlaceholder={formatMessage({
        id: "general.enterCompanyNameOrDuns",
      })}
      onClearFiltersClick={onClearFiltersClick}
      initialSearchValue={initialSearchValue}
      selectedFilters={getSelectedFilters()}
      filters={[
        {
          label: formatMessage({ id: "general.createdSince" }),
          content: (
            <DatePicker
              value={
                filterObj.createdAt
                  ? moment(filterObj.createdAt, "DD/MM/YYYY")
                  : undefined
              }
              onChange={createdAt => {
                mergeFilters({
                  createdAt: createdAt?.format("DD/MM/YYYY"),
                });
              }}
            />
          ),
        },

        {
          label: formatMessage({
            id: "general.status",
          }),
          content: (
            <CheckableTags
              data-test-id="pending"
              value={filterObj.companyStatuses}
              onChange={companyStatuses =>
                mergeFilters({
                  companyStatuses,
                })
              }
              options={[
                {
                  value: CompanyStatus.PENDING,
                  label: formatMessage({ id: "general.pending" }),
                },
                {
                  value: CompanyStatus.REJECTED,
                  label: formatMessage({ id: "general.rejected" }),
                },
                {
                  value: CompanyStatus.VALIDATED,
                  label: formatMessage({ id: "general.validated" }),
                },
              ]}
            />
          ),
        },
      ]}
    />
  );
};

export default CompanyPendingSearch;
