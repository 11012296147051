import React from "react";
import { useIntl } from "react-intl";

import { Modal, toaster } from "@trace-one/design-system";

import { CumdAPI } from "../../apis";

export interface RemoveFromGroupModalProps {
  setIsModalVisible?: any;
  isModalVisible?: boolean;
  subscriptionId?: string;
  associatedCompanyId?: string;
  fetchSharedSubscriptions?: any;
}

const RemoveFromGroupModal: React.FC<RemoveFromGroupModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  subscriptionId,
  associatedCompanyId,
  fetchSharedSubscriptions,
}) => {
  const { formatMessage } = useIntl();

  const handleDeleteSharedSubscription = async () => {
    try {
      await CumdAPI.deleteSharedSubscription(subscriptionId, {
        associatedCompanyId: associatedCompanyId,
      });

      setIsModalVisible(false);

      await fetchSharedSubscriptions();

      toaster.open({
        message: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        description: formatMessage({
          id: "groupUpdate.toast.success.text",
        }),
        type: "confirmation",
      });
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "groupUpdate.toast.error.title" }),
        description: formatMessage({
          id: "groupUpdate.toast.error.text",
        }),

        type: "alert",
      });
    }
  };

  return (
    <div>
      <Modal.Simple
        size="s"
        illustrationName="delete"
        illustrationColor="grey"
        title={formatMessage({ id: "sharedSubscription.modal.remove.title" })}
        open={isModalVisible}
        primaryButtonText={formatMessage({
          id: "general.confirm",
        }).toUpperCase()}
        onPrimaryButtonClick={handleDeleteSharedSubscription}
        secondaryButtonText={formatMessage({
          id: "general.cancel",
        }).toUpperCase()}
        onSecondaryButtonClick={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        data-test-id="remove-subscription-from-group-modal"
      >
        {formatMessage({ id: "sharedSubscription.modal.remove.text" })}
      </Modal.Simple>
    </div>
  );
};

export default RemoveFromGroupModal;
