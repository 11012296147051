import { SessionKeys, buildSessionStorage } from "./storage";

export const backToAppStorage = buildSessionStorage(SessionKeys.BACK_TO_APP);

export const SearchFiltersStorage = Object.freeze({
  COMPANY_ADMIN_USER_LIST: buildSessionStorage(
    SessionKeys.COMPANY_ADMIN_USER_FILTERS
  ),
  // TO_ADMIN_USER_LIST: buildSessionStorage(SessionKeys.TO_ADMIN_USER_FILTERS),
  TO_ADMIN_REGISTERED_USER_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_REGISTERED_USER_FILTERS
  ),
  TO_ADMIN_PENDING_USER_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_PENDING_USER_FILTERS
  ),

  TO_ADMIN_REGISTERED_COMPANY_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_REGISTERED_COMPANY_FILTERS
  ),
  TO_ADMIN_PENDING_COMPANY_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_PENDING_COMPANY_FILTERS
  ),
  TO_ADMIN_RELATION_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_RELATION_FILTERS
  ),

  GROUP_LIST: buildSessionStorage(SessionKeys.GROUP_FILTERS),
  GROUP_CREATE_COMPANY_LIST: buildSessionStorage(
    SessionKeys.GROUP_COMPANY_LIST_FILTERS
  ),
});

export const TablePaginationStorage = Object.freeze({
  COMPANY_ADMIN_USER_LIST: buildSessionStorage(
    SessionKeys.COMPANY_ADMIN_USER_PAGINATION
  ),
  TO_ADMIN_REGISTERED_USER_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_REGISTERED_USER_PAGINATION
  ),
  TO_ADMIN_PENDING_USER_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_PENDING_USER_PAGINATION
  ),
  TO_ADMIN_REGISTERED_COMPANY_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_REGISTERED_COMPANY_PAGINATION
  ),
  TO_ADMIN_PENDING_COMPANY_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_PENDING_COMPANY_PAGINATION
  ),
  TO_ADMIN_RELATION_LIST: buildSessionStorage(
    SessionKeys.TO_ADMIN_RELATION_PAGINATION
  ),
  CREATE_USER_PAGE_USER_LIST: buildSessionStorage(
    SessionKeys.CREATE_USER_PAGE_USER_PAGINATION
  ),
  GROUP_LIST: buildSessionStorage(SessionKeys.GROUP_PAGINATION),
  GROUP_DETAILS: buildSessionStorage(SessionKeys.GROUP_DETAILS),
  GROUP_CREATE_COMPANY_LIST: buildSessionStorage(
    SessionKeys.GROUP_COMPANY_LIST_PAGINATION
  ),
});
