import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Select,
  Input,
  Heading,
  Button,
  Graphic,
  Tooltip,
} from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Divider, Form, Typography } from "antd";
import { v4 as uuid } from "uuid";

import { CumdAPI } from "../../../../../apis";
import AsyncSearchSelect from "../../../../../components/AsyncSearchSelect";
import ProductCategoryTreeSelect from "../../../../../components/ProductCategoryTreeSelect";
import { useAppDispatch } from "../../../../../reduxStore";
import { fetchCompanyActivities } from "../../../../../reduxStore/shared/asyncActions";
import { selectCompanyActivitiesData } from "../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../reduxStore/user/selectors";
import FoundedInfo from "../../CompanyChangeDunsForm/ChangeDuns/FoundedInfo";

import styles from "./CompanyInformation.module.less";

interface CompanyInformationProps {
  setStepIndex?: (step: number) => void;
  setCompanyId?: any;
  isCompanyCardSelected?: boolean;
  countryOptions?: object[];
  country?: string;
  setCountry?: (string) => void;
  city?: string;
  setCity?: (string) => void;
  postalCode?: string;
  setPostalCode?: (string) => void;
  address?: string;
  setAddress?: (string) => void;
  companyActivity?: string;
  setCompanyActivity?: (string) => void;
  category?: {
    id: string;
    categoryListId: string;
    name: string;
  }[];
  // eslint-disable-next-line no-empty-pattern
  setCategory?: ([]) => void;
  companyValue?: any;
  setCompanyValue?: (any) => void;
  companySearchValue?: string;
  setCompanySearchValue?: (string) => void;
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  setCompanyId,
  setStepIndex,
  isCompanyCardSelected,
  countryOptions,
  country,
  setCountry,
  city,
  setCity,
  postalCode,
  setPostalCode,
  address,
  setAddress,
  companyActivity,
  setCompanyActivity,
  category,
  setCategory,
  companyValue,
  setCompanyValue,
  companySearchValue,
  setCompanySearchValue,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const [form] = Form.useForm();

  const companiesActivitiesOptions = useSelector(
    selectCompanyActivitiesData
  ).data.map(({ itemCode, text }) => ({ value: itemCode, label: text }));

  useEffect(() => {
    dispatch(
      fetchCompanyActivities({
        languageCode,
      })
    );
  }, []);

  const getCompanyOptions = () => {
    return CumdAPI.getCompanyByDunsFilters({
      searchTerm: companySearchValue,
      companyName: null,
      companyCountry: country,
      companyCity: city,
      companyPostalCode: postalCode,
      companyAddress: address,
    }).then(({ data }) => {
      return data.map(company => {
        const updateNotAllowed = company.companyId !== null;

        let label;
        if (updateNotAllowed) {
          label = (
            <Tooltip
              text={
                company?.companyState !== null
                  ? `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
                  : `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`
              }
            >
              <div className={styles.labelWrapper}>
                {company?.companyState !== null ? (
                  <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`}</div>
                ) : (
                  <div>{`${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`}</div>
                )}
                <span className={styles.icon}>
                  <Graphic name="alert" />
                </span>
              </div>
            </Tooltip>
          );
        } else {
          label =
            company?.companyState !== null
              ? `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress} - ${company.companyState}`
              : `${company.companyName} / ${company.companyDuns} - ${company.companyPostalCode} - ${company.companyAddress}`;
        }

        return {
          value: company.companyDuns,
          label,
          updateNotAllowed: updateNotAllowed,
          ...company,
        };
      });
    });
  };

  const helpMessage = (
    <div className={styles.helpMessage}>
      <span>
        {formatMessage({
          id: "companyCreateDuns.changeDuns.selectCompanyName.errorText",
        })}
      </span>
      <br />
      <div>
        <span className={styles.helpMessageExtraText}>
          {formatMessage({
            id: "companyCreateDuns.changeDuns.selectCompanyName.errorAdditionalText",
          })}
        </span>
        <Typography.Link
          onClick={() => {
            history.push(`/companies/${companyValue.companyId}`);
          }}
        >
          {" "}
          {companyValue?.companyName}
        </Typography.Link>
      </div>
    </div>
  );

  const handleCountryChange = value => {
    setCountry(value);
    setCompanyValue(null);
    form.setFieldsValue({
      companyName: null,
    });
  };

  return (
    <div className={styles.companyInfoSection}>
      <Heading size="xs">
        {formatMessage({ id: "companyCreate.companyInfo.title" })}
      </Heading>
      <Form name="basic" form={form}>
        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.country" })} required>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
            >
              <Select
                placeholder={formatMessage({ id: "general.selectValue" })}
                showSearch
                allowClear
                options={countryOptions}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleCountryChange}
                defaultValue={country}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.innerItemWrapper}>
          <div>
            <Label title={formatMessage({ id: "general.city" })}>
              <Form.Item name="city">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                  defaultValue={city}
                />
              </Form.Item>
            </Label>
          </div>
          <div>
            <Label title={formatMessage({ id: "general.zipcode" })}>
              <Form.Item name="zipCode">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setPostalCode(e.target.value);
                  }}
                  defaultValue={postalCode}
                />
              </Form.Item>
            </Label>
          </div>
        </div>

        <div className={styles.itemWrapper}>
          <Label title={formatMessage({ id: "general.address" })}>
            <Form.Item name="address">
              <Input
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                onChange={e => {
                  setAddress(e.target.value);
                }}
                defaultValue={address}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.itemWrapperAsyncSelect}>
          <Label
            title={formatMessage({
              id: "companyCreateDuns.changeDuns.selectCompanyName",
            })}
            required
          >
            <Form.Item
              name="companyName"
              validateStatus={companyValue?.updateNotAllowed ? "error" : null}
              help={companyValue?.updateNotAllowed ? helpMessage : null}
            >
              <AsyncSearchSelect
                disabled={!country}
                allowClear
                showSearch
                placeholder={formatMessage({
                  id: "general.enterValue",
                })}
                value={companyValue}
                defaultValue={companyValue}
                searchValue={companySearchValue}
                onSearch={setCompanySearchValue}
                onChange={(value, option) => {
                  setCompanyValue(option);
                  setCompanyId(uuid());
                }}
                onAsyncSearch={getCompanyOptions}
                minLengthToSearch={3}
                labelInValue
              />
            </Form.Item>
          </Label>
        </div>

        {companyValue && !companyValue?.updateNotAllowed && (
          <>
            <Divider dashed />
            <FoundedInfo company={companyValue} />
          </>
        )}

        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({
              id: "general.activity",
            })}
            required
          >
            <Form.Item name="activity">
              <Select
                placeholder={formatMessage({
                  id: "general.selectValue",
                })}
                showSearch
                allowClear
                options={companiesActivitiesOptions}
                optionFilterProp="children"
                onChange={setCompanyActivity}
                defaultValue={companyActivity}
              />
            </Form.Item>
          </Label>
        </div>

        <div className={styles.itemWrapper}>
          <Label
            title={formatMessage({
              id: "companyGeneralInfo.productCategories",
            })}
            required
          >
            <Form.Item name="productCategories">
              <ProductCategoryTreeSelect
                category={category}
                setCategory={setCategory}
              />
            </Form.Item>
          </Label>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            disabled={
              !companyValue || category.length === 0 || !companyActivity
            }
            onClick={() => {
              isCompanyCardSelected && setStepIndex(2);
            }}
            data-test-id="create-company-second-step-next-btn"
          >
            {formatMessage({ id: "general.next" })}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CompanyInformation;
