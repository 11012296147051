import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Select,
  Input,
  Heading,
  Button,
  Spinner,
  Paragraph,
} from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Form } from "antd";

import { Titles } from "../../../../../../../shared/constants";
import { ErrorCode } from "../../../../../../../shared/errors";
import { Languages } from "../../../../../../../translations";
import styles from "../../TestUserInformation.module.less";

interface AddFirstUserProps {
  testCompanyId?: string;
  testCompanyName?: string;
  jobTitlesOptions?: object[];
  languageOptions?: object[];
  setTestTitle?: (string) => void;
  testTitle?: string;
  // eslint-disable-next-line no-empty-pattern
  setTestJobTitle?: ({}) => void;
  testJobTitle?: number;
  setTestLastName?: (string) => void;
  testLastName?: string;
  setTestFirstName?: (string) => void;
  testFirstName?: string;
  // eslint-disable-next-line no-empty-pattern
  setTestLanguage?: ({}) => void;
  testLanguage?: string;
  testPhoneNumber?: string;
  setTestPhoneNumber?: (string) => void;
  setTestEmail?: (string) => void;
  testEmail?: string;
  setTestConfirmationEmail?: (string) => void;
  testConfirmationEmail?: string;
  testEmailIsValid?: boolean;
  testEmailErrorCode?: string;
  setIsValid?: (boolean) => void;
  handleCreateTestUserAndCompany?: any;
  checkIfEmailExists?: any;
}

const AddFirstUser: React.FC<AddFirstUserProps> = ({
  testCompanyId,
  testCompanyName,
  jobTitlesOptions,
  languageOptions,
  setTestTitle,
  testTitle,
  setTestJobTitle,
  testJobTitle,
  setTestLastName,
  testLastName,
  setTestFirstName,
  testFirstName,
  setTestLanguage,
  testLanguage,
  testPhoneNumber,
  setTestPhoneNumber,
  setTestEmail,
  testEmail,
  setTestConfirmationEmail,
  testConfirmationEmail,
  testEmailIsValid,
  testEmailErrorCode,
  setIsValid,
  handleCreateTestUserAndCompany,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [readyToRedirectToCompanyList, setReadyToRedirectToCompanyList] =
    useState(false);

  const titleOptions = Titles.map(title => ({
    value: title,
    label: title,
  }));

  const createAndRedirectToCompanyListPage = () => {
    handleCreateTestUserAndCompany();
    setIsLoading(true);
    setTimeout(() => {
      form.setFieldsValue({
        emailConfirmation: "",
      });
      setTestConfirmationEmail("");
      setReadyToRedirectToCompanyList(true);
      setIsLoading(false);
    }, 5000);
  };

  if (readyToRedirectToCompanyList && testEmailIsValid) {
    history.push(`/companies/list`);
  }

  return (
    <>
      {!isLoading ? (
        <div>
          <Heading size="xs">
            {formatMessage({ id: "companyCreate.firstUserInfo.title" })}
          </Heading>
          <Heading size="xxs">
            {formatMessage({ id: "companyCreate.firstUserInfo.description" })}
          </Heading>
          <Form name="basic" form={form}>
            <div className={styles.itemWrapper}>
              <Label title={formatMessage({ id: "general.companyName" })}>
                <Form.Item name="companyName">
                  <Input defaultValue={testCompanyName} disabled />
                </Form.Item>
              </Label>
            </div>

            <div className={styles.innerItemWrapper}>
              <div>
                <Label title={formatMessage({ id: "general.title" })} required>
                  <Form.Item name="title">
                    <Select
                      placeholder={formatMessage({
                        id: "general.selectValue",
                      })}
                      showSearch
                      allowClear
                      options={titleOptions}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={setTestTitle}
                      defaultValue={testTitle}
                    />
                  </Form.Item>
                </Label>
              </div>
              <div>
                <Label
                  title={formatMessage({ id: "general.jobTitle" })}
                  required
                >
                  <Form.Item name="jobTitle">
                    <Select
                      placeholder={formatMessage({
                        id: "general.selectValue",
                      })}
                      showSearch
                      allowClear
                      options={jobTitlesOptions}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={setTestJobTitle}
                      defaultValue={testJobTitle}
                    />
                  </Form.Item>
                </Label>
              </div>
            </div>

            <div className={styles.innerItemWrapper}>
              <div>
                <Label
                  title={formatMessage({ id: "general.lastName" })}
                  required
                >
                  <Form.Item name="lastName">
                    <Input
                      placeholder={formatMessage({
                        id: "general.enterValue",
                      })}
                      onChange={e => {
                        setTestLastName(e.target.value);
                      }}
                      defaultValue={testLastName}
                    />
                  </Form.Item>
                </Label>
              </div>

              <div>
                <Label
                  title={formatMessage({ id: "general.firstName" })}
                  required
                >
                  <Form.Item name="firstName">
                    <Input
                      placeholder={formatMessage({
                        id: "general.enterValue",
                      })}
                      onChange={e => {
                        setTestFirstName(e.target.value);
                      }}
                      defaultValue={testFirstName}
                    />
                  </Form.Item>
                </Label>
              </div>
            </div>

            <div className={styles.innerItemWrapper}>
              <div>
                <Label
                  title={formatMessage({ id: "general.language" })}
                  required
                >
                  <Form.Item name="language">
                    <Select
                      showSearch
                      allowClear
                      defaultValue={languageOptions.find(
                        lang => lang["value"] === Languages.ENGLISH
                      )}
                      options={languageOptions}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={setTestLanguage}
                    />
                  </Form.Item>
                </Label>
              </div>

              <div>
                <Label title={formatMessage({ id: "general.phone" })}>
                  <Form.Item name="phoneNumber">
                    <Input
                      placeholder={formatMessage({
                        id: "general.enterValue",
                      })}
                      onChange={e => {
                        setTestPhoneNumber(e.target.value);
                      }}
                      type="number"
                      defaultValue={testPhoneNumber}
                    />
                  </Form.Item>
                </Label>
              </div>
            </div>

            <div className={styles.itemWrapper}>
              <Label title={formatMessage({ id: "general.email" })} required>
                <Form.Item
                  name="email"
                  validateStatus={testEmailIsValid === false && "error"}
                  help={
                    testEmailIsValid === false &&
                    formatMessage({
                      id:
                        testEmailErrorCode === ErrorCode.EMAIL_ALREADY_EXISTS
                          ? "companyCreate.firstUserInfo.toast.description"
                          : "toast.error.invalid.email.description",
                    })
                  }
                >
                  <Input
                    placeholder={formatMessage({
                      id: "general.enterValue",
                    })}
                    onChange={e => {
                      setTestEmail(e.target.value);
                      if (e.target.value === "") {
                        setIsValid(null);
                      }
                    }}
                    defaultValue={testEmail}
                  />
                </Form.Item>
              </Label>
            </div>

            <div className={styles.itemWrapper}>
              <Label
                title={formatMessage({ id: "general.confirmEmail" })}
                required
              >
                <Form.Item
                  name="emailConfirmation"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: "companyCreate.firstUserInfo.confirmationEmail.error.description",
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            formatMessage({
                              id: "companyCreate.firstUserInfo.confirmationEmail.error.description",
                            })
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={formatMessage({
                      id: "general.enterValue",
                    })}
                    onChange={e => {
                      setTestConfirmationEmail(e.target.value);
                    }}
                    defaultValue={testConfirmationEmail}
                  />
                </Form.Item>
              </Label>
            </div>

            <div className={styles.btnWrapper}>
              <Button
                disabled={
                  !testTitle ||
                  !testJobTitle ||
                  !testLastName ||
                  !testFirstName ||
                  !testEmail ||
                  !testConfirmationEmail ||
                  testEmail !== testConfirmationEmail ||
                  !testLanguage
                }
                onClick={createAndRedirectToCompanyListPage}
                data-test-id="create-test-company-third-step-save-btn"
              >
                {formatMessage({
                  id: "companyCreate.firstUserInfo.saveAndDoneBtn",
                })}
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner size="large" />
          <div>
            <Paragraph size="m">
              {formatMessage(
                { id: "companyCreate.firstUserInfo.spinner.msg" },
                { br: <br /> }
              )}
            </Paragraph>
          </div>
        </div>
      )}
    </>
  );
};

export default AddFirstUser;
