import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Button, Input, Heading, toaster } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Modal, Form } from "antd";

import { CumdAPI } from "../../../../../../../apis";
import { UserData } from "../../../../../../../models";
import { ErrorCode } from "../../../../../../../shared/errors";

import styles from "./EditUserLogin.module.less";

interface EditUserLoginProps {
  user: UserData;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
}

const EditUserLogin: React.FC<EditUserLoginProps> = ({
  user,
  isModalVisible,
  setIsModalVisible,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [login, setLogin] = useState<string>(user?.userLogin);
  const [confirmEmail, setConfirmEmail] = useState<string>(user?.userLogin);

  const [emailErrorCode, setEmailErrorCode] = useState<string>(null);

  const [emailIsValid, setEmailIsValid] = useState<boolean>(null);

  const [emailsMatch, setEmailsMatch] = useState(true);

  const closeModal = () => {
    setIsModalVisible(false);

    setLogin(user?.userLogin);
    setConfirmEmail(user?.userLogin);
  };

  const disableBtn = login === "" || confirmEmail === "";

  const validateEmail = login => {
    return CumdAPI.checkUserEmailAlreadyExists(login).then(({ data }) => {
      setEmailIsValid(data.isValid);
      setEmailErrorCode(data.errorCode);

      switch (data.errorCode) {
        case ErrorCode.EMAIL_ALREADY_EXISTS:
          toaster.open({
            message: formatMessage({
              id: "companyCreate.firstUserInfo.toast.title",
            }),
            description: formatMessage({
              id: "companyCreate.firstUserInfo.toast.description",
            }),
            type: "alert",
          });
          break;
        case ErrorCode.EMAIL_INVALID:
          toaster.open({
            message: formatMessage({
              id: "toast.error.invalid.email.title",
            }),
            description: formatMessage({
              id: "toast.error.invalid.email.description",
            }),
            type: "alert",
          });
          break;
      }

      return data.isValid;
    });
  };

  const handleEditUserLogin = async () => {
    if (login !== confirmEmail) {
      setEmailsMatch(false);
      return;
    }
    const emailIsValid = await validateEmail(login);

    if (!emailIsValid) {
      return;
    }

    try {
      await CumdAPI.updateUserEmail(user?.userId, {
        newEmail: login,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "userLoginUpdate.toast.confirmation.title",
        }),
        type: "confirmation",
      });

      closeModal();

      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "userLoginUpdate.toast.alert.title" }),
        description: formatMessage({
          id: "userLoginUpdate.toast.alert.description",
        }),
        type: "alert",
      });
    }
  };

  return (
    <Form name="basic" form={form} preserve={false}>
      <Modal
        wrapClassName={styles.root}
        title={
          <Heading size="xs">
            {formatMessage({ id: "general.changeLogin" })}
          </Heading>
        }
        open={isModalVisible}
        footer={
          <Button
            htmlType="submit"
            onClick={handleEditUserLogin}
            disabled={disableBtn}
            data-test-id="update-user-login-save-btn"
          >
            {formatMessage({ id: "general.save" })}
          </Button>
        }
        onCancel={closeModal}
        destroyOnClose={true}
        data-test-id="update-user-login-modal"
      >
        <div className={styles.wrapper}>
          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.email" })} required>
              <Form.Item
                name="login"
                validateStatus={emailIsValid === false && "error"}
                help={
                  emailIsValid === false &&
                  formatMessage({
                    id:
                      emailErrorCode === ErrorCode.EMAIL_ALREADY_EXISTS
                        ? "companyCreate.firstUserInfo.toast.description"
                        : "toast.error.invalid.email.description",
                  })
                }
              >
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  defaultValue={login}
                  data-test-id="update-user-login-email-address-input"
                  onChange={e => {
                    setLogin(e.target.value);
                    setEmailsMatch(e.target.value === confirmEmail);
                    if (e.target.value === "") {
                      setEmailIsValid(null);
                    }
                  }}
                  value={login}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label
              title={formatMessage({ id: "general.confirmEmail" })}
              required
            >
              <Form.Item
                validateStatus={!emailsMatch && "error"}
                help={
                  !emailsMatch &&
                  formatMessage({
                    id: "companyCreate.firstUserInfo.confirmationEmail.error.description",
                  })
                }
                name="confirmEmail"
              >
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  defaultValue={confirmEmail}
                  onChange={e => {
                    setConfirmEmail(e.target.value);
                    setEmailsMatch(e.target.value === login);
                  }}
                  data-test-id="update-user-login-confirm-email-input"
                />
              </Form.Item>
            </Label>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default EditUserLogin;
