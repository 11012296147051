import React from "react";
import { useIntl } from "react-intl";

import { CompanyForTraceoneAdmin } from "@trace-one/api-clients.cumd";
import { Divider, Heading, Paragraph, Button } from "@trace-one/design-system";

import { GroupModalMode } from "../../../../shared/constants";
import styles from "../../GroupModal.module.less";
export interface CompanyCardProps {
  mode?: string;
  company?: CompanyForTraceoneAdmin;
  countriesOptions?: { value: string; label: string }[];
  companiesInBasket?: CompanyForTraceoneAdmin[];
  setCompaniesInBasket?: any;
  companyActivities?: any;
  addCompanyToGroup?: any;
}
const CompanyCard: React.FC<CompanyCardProps> = ({
  mode,
  company,
  countriesOptions,
  companiesInBasket,
  setCompaniesInBasket,
  companyActivities,
  addCompanyToGroup,
}) => {
  const { formatMessage } = useIntl();

  const isAdded = companiesInBasket.some(
    c => c.companyId === company.companyId
  );

  const country = countriesOptions?.find(country => {
    return country.value === company.companyCountry;
  });

  const addOrRemoveCompany = () => {
    if (isAdded) {
      setCompaniesInBasket(
        companiesInBasket.filter(c => c.companyId !== company.companyId)
      );
    } else {
      setCompaniesInBasket([...companiesInBasket, company]);
    }
  };

  const getActivityText = (activityId, companyActivities) => {
    const activity = companyActivities.find(
      activity => Number(activity?.itemCode) === activityId
    );
    return activity ? activity.text : "";
  };

  return (
    <div data-test-id="group-modal-companies-list-company-card">
      <div className={styles.card}>
        <div className={styles.cardInfo}>
          <Heading size="xs">{company?.companyDisplayName}</Heading>

          <div>
            <Paragraph data-test-id="group-modal-company-card-duns">
              {company?.companyDuns === "" || company?.companyDuns === null
                ? ""
                : company?.companyDuns}
            </Paragraph>

            <Divider color="grey-3" type="vertical" height="20px" />

            <Paragraph data-test-id="group-modal-company-card-companyType">
              {getActivityText(company?.companyActivityId, companyActivities)}
            </Paragraph>

            <Divider color="grey-3" type="vertical" height="20px" />

            <Paragraph data-test-id="group-modal-company-card-address">
              {`${company?.companyAddress1 ? company?.companyAddress1 : "-"},
              ${company.companyCountry ? country?.label : "-"},
              ${company?.companyCity ? company?.companyCity : "-"}, 
              ${company?.companyPostalCode ? company?.companyPostalCode : "-"}
              `}
            </Paragraph>

            <Divider color="grey-3" type="vertical" height="20px" />

            <Paragraph data-test-id="group-modal-company-card-group-name">
              {company?.groupName ? company?.groupName : "-"}
            </Paragraph>
          </div>
        </div>

        <div className={styles.cardButton}>
          <Button
            danger={isAdded}
            type="secondary"
            disabled={company?.groupName !== null}
            showTooltip={company?.groupName !== null}
            tooltipProps={{
              text: formatMessage(
                {
                  id: "groupCreate.tooltip.isPartOfGroup",
                },
                {
                  groupName: company?.groupName,
                }
              ),
            }}
            onClick={
              mode === GroupModalMode.CREATE_GROUP
                ? () => addOrRemoveCompany()
                : () => addCompanyToGroup(company?.companyId)
            }
          >
            {isAdded
              ? formatMessage({
                  id: "companySubscriptionForm.contract.remove",
                })
              : formatMessage({ id: "general.add" })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
