import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { Container, Button } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";

import Spinner from "components/Spinner";
import { BaseHocProps, withCompanyActivities } from "shared/hocs";

import usePermissions from "../../../../core/oidc/usePermissions";
import CompanyList from "../../containers/CompanyList";

import styles from "./CompanyListPage.module.less";

const enhance = compose<React.FC<BaseHocProps>>(withCompanyActivities());

export const CompanyListPage: React.FC<BaseHocProps> = ({ isInitiated }) => {
  const { hasRoleSuperAdmin, hasRoleAdministrator } = usePermissions();
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <Container isPageWrapper>
      <div className={styles.titleWrapper}>
        <Title
          value={formatMessage({
            id: "general.registeredCompanies",
          }).toUpperCase()}
          level={1}
        />
        {(hasRoleSuperAdmin || hasRoleAdministrator) && (
          <Button
            onClick={() => {
              history.push(`/companies/create-company`);
            }}
            data-test-id="create-company-btn"
          >
            {formatMessage({ id: "general.createCompany" })}
          </Button>
        )}
      </div>
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <CompanyList />
      </Spinner>
    </Container>
  );
};

export default enhance(CompanyListPage);
