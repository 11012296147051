import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

export const _selectGroupCompanyList = (state: RootState) =>
  state.groupCompanyList;

export const selectGroupCompanies = createSelector(
  _selectGroupCompanyList,
  ({ data }) => data
);
export const selectGroupCompaniesSkipAndTakeQueryStats = createSelector(
  _selectGroupCompanyList,
  ({ skipAndTakeQueryStats }) => skipAndTakeQueryStats
);
export const selectIsGroupCompaniesLoading = createSelector(
  _selectGroupCompanyList,
  ({ isLoading }) => isLoading
);
