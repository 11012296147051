import { useEffect, useState } from "react";

import { CumdAPI } from "../../../../../apis";
import useToast from "../../../../../shared/hooks/useToast";

const useGroupDetails = companyGroupId => {
  const toast = useToast();

  const [groupData, setGroupData] = useState(null);
  const fetchGroupDetails = async id => {
    try {
      const { data } = await CumdAPI.getGroupsByFilters();
      const group = data?.companyGroups.find(
        group => group.companyGroupId === id
      );
      setGroupData(group);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    fetchGroupDetails(companyGroupId);
  }, [companyGroupId]);

  return groupData;
};

export default useGroupDetails;
