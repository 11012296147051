import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { BadgeList, TagV1 as Tag } from "@trace-one/design-system";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import useUserStatuses from "pages/Users/hooks/useUserStatuses";

import { UserForUIAdminData } from "models";

import AppIcons from "components/AppIcons";
import { UserStatus } from "shared/constants";

import usePermissions from "../../../../../core/oidc/usePermissions";
import { selectJobTitlesData } from "../../../../../reduxStore/shared/selectors";
import { CompanyAdminUserTableColumn } from "../models";
import UserAction from "../UserAction";

export default function useUserTable({
  users,
  refetchUsers,
  responsibilitiesData,
}: {
  users: UserForUIAdminData[];
  refetchUsers: () => void;
  responsibilitiesData?: any;
}) {
  const { isCompanyAdmin, isTraceoneAdmin } = usePermissions();

  const responsibilitiesMap = responsibilitiesData?.reduce(
    (map, responsibility) => {
      map[responsibility.id] = responsibility.text;
      return map;
    },
    {}
  );

  const { formatMessage } = useIntl();

  const history = useHistory();

  const { userStatusLabels, userStatusTagColors } = useUserStatuses();

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );
  const jobTitlesMap = jobTitlesOptions.reduce<{
    [itemCode: string]: string;
  }>((prev, current) => ({ ...prev, [current.value]: current.label }), {});

  const columns: ColumnsType<CompanyAdminUserTableColumn> = [
    {
      title: formatMessage({
        id: "general.name",
      }),
      dataIndex: "userFullname",
      key: "userFullname",
      width: 180,
      ellipsis: true,
      render: (name, record) => {
        if (record.userStatus !== UserStatus.ENABLED) {
          return name;
        }

        return isCompanyAdmin && !isTraceoneAdmin ? (
          <Typography.Link
            onClick={() => {
              history.push(`/users/access/${record.key}`);
            }}
          >
            {name}
          </Typography.Link>
        ) : (
          <Typography>{name}</Typography>
        );
      },
    },
    {
      title: formatMessage({
        id: "general.email",
      }),
      dataIndex: "userLogin",
      key: "userLogin",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.jobTitle",
      }),
      dataIndex: "userJobTitle",
      key: "userJobTitle",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "userList.table.administrator",
      }),
      dataIndex: "administrator",
      key: "administrator",
      width: 100,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.responsibilities",
      }),
      dataIndex: "responsibilities",
      key: "responsibilities",
      width: 240,
      render: (responsibilities: number[], record) => {
        const responsibilitiesList = responsibilities
          ?.map(responsibilityId => responsibilitiesMap?.[responsibilityId])
          .filter(item => item);

        let text = responsibilitiesList?.slice(0, 1);

        if (responsibilitiesList?.length === 0) {
          return null;
        } else if (responsibilitiesList?.length === 1) {
          return <span>{text}</span>;
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "8px" }}>{text}</span>
              <BadgeList options={responsibilitiesList?.slice(1)} />
            </div>
          );
        }
      },
    },
    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applications",
      key: "applications",
      width: 200,
      render: (applications: number[], record) => {
        return <AppIcons applications={applications} />;
      },
    },

    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "userStatus",
      key: "userStatus",
      width: 140,
      render: (userStatus, record) => {
        let status = userStatus;
        if (record.rawData?.userLocked) status = UserStatus.LOCKED;
        if (record.rawData.isMainContact === true) {
          return (
            <Tag
              label={formatMessage({
                id: "general.isMainContact",
              })}
              color={userStatusTagColors[status]}
              size="medium"
              mode="light"
            />
          );
        } else {
          return (
            <Tag
              label={userStatusLabels[status]}
              color={userStatusTagColors[status]}
              size="medium"
              mode="light"
            />
          );
        }
      },
    },

    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <UserAction
          data={record.rawData}
          users={users}
          refetchUsers={refetchUsers}
        />
      ),
    },
  ];

  const data = users.map(user => {
    const result: CompanyAdminUserTableColumn = {
      key: user.userId,
      userFullname: [user.userFirstName, user.userLastName]
        .filter(item => !!item)
        .join(" "),
      userLogin: user.userLogin,
      userJobTitle: jobTitlesMap[user.userJobTitleId],
      administrator: formatMessage({
        id: user.isAccountAdministrator ? "general.yes" : "general.no",
      }),
      responsibilities: user.responsibilities,
      applications: user.applicationTypeIds.filter(
        app => app !== 40 && user.applicationTypeIds
      ),
      userStatus: user.userStatus,
      rawData: user,
    };
    return result;
  });
  return { columns, data };
}
