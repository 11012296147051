import { useIntl } from "react-intl";

import { Tag } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import useSubscriptionStatuses from "pages/Companies/hooks/useSubscriptionStatuses";

import { SubscriptionData } from "models";

import AppIcon from "components/AppIcon";
import { formatDate } from "shared/utils/dateUtils";

import { CompanySubscriptionTableColumn } from "../models";
import SubscriptionAction from "../SubscriptionAction";

export default function useCompanySubscriptionTable({
  companyId,
  companyGroupId,
  companySubscriptions,
  refetchData,
  setSharedCompaniesLength,
}: {
  companyId: string;
  companyGroupId?: string;
  companySubscriptions: SubscriptionData[];
  refetchData: () => void;
  setSharedCompaniesLength?: any;
}) {
  const { formatMessage } = useIntl();

  const { subscriptionStatusLabels, subscriptionTagColors } =
    useSubscriptionStatuses();

  const columns: ColumnsType<CompanySubscriptionTableColumn> = [
    {
      title: formatMessage({
        id: "companySubscriptionList.subscriptionId",
      }),
      dataIndex: "key",
      key: "key",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "companySubscriptionList.offerNames",
      }),
      dataIndex: "offerNames",
      key: "offerNames",
      width: 200,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.creationDate",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      ellipsis: true,
      render: creationDate => formatDate(creationDate),
    },
    {
      title: formatMessage({
        id: "companySubscriptionList.offersTypes",
      }),
      dataIndex: "offerTypes",
      key: "offerTypes",
      width: 140,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "companySubscriptionList.businessModel",
      }),
      dataIndex: "businessModel",
      key: "businessModel",
      width: 140,
      ellipsis: true,
    },
    {
      title: formatMessage({
        id: "general.applications",
      }),
      dataIndex: "applicationTypeId",
      key: "applicationTypeId",
      width: 200,
      render: (applicationTypeId: number, record) => {
        return <AppIcon type={applicationTypeId} />;
      },
    },
    {
      title: formatMessage({
        id: "general.status",
      }),
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (status: string, record) => {
        return (
          <Tag
            label={subscriptionStatusLabels[status]}
            color={subscriptionTagColors[status]}
            size="medium"
            mode="light"
          />
        );
      },
    },
    {
      title: formatMessage({
        id: "general.actions",
      }),
      dataIndex: "actions",
      key: "actions",
      width: 80,
      align: "center",
      fixed: "right",
      ellipsis: true,
      render: (_, record) => (
        <SubscriptionAction
          companyId={companyId}
          data={record.rawData}
          refetchData={refetchData}
          companyGroupId={companyGroupId}
          setSharedCompaniesLength={setSharedCompaniesLength}
        />
      ),
    },
  ];

  const data = companySubscriptions.map(subscription => {
    const result: CompanySubscriptionTableColumn = {
      key: subscription.functionalId.toString(),
      offerNames: subscription.offers
        .map(({ offerName }) => offerName)
        .join(", "),
      createdAt: subscription.createdAt,
      offerTypes: subscription.offers
        .map(({ offerType }) => offerType)
        .join(", "),
      businessModel: subscription.businessModel,
      applicationTypeId: subscription.applicationTypeId,
      status: subscription.status,
      rawData: subscription,
    };
    return result;
  });

  return { columns, data };
}
