import { useIntl } from "react-intl";

import { CompanyForTraceoneAdmin } from "@trace-one/api-clients.cumd";
import { Typography, Pagination } from "@trace-one/design-system";

import styles from "../../GroupModal.module.less";
import CompanyCard from "../CompanyCard";

export interface CompaniesListProps {
  mode?: string;
  companies?: CompanyForTraceoneAdmin[];
  skipAndTakeQueryStats?: any;
  paginationQuery?: any;
  setPaginationQuery?: any;
  countriesOptions?: { value: string; label: string }[];
  companiesInBasket?: CompanyForTraceoneAdmin[];
  setCompaniesInBasket?: any;
  companyActivities?: any;
  addCompanyToGroup?: any;
}
const CompaniesList: React.FC<CompaniesListProps> = ({
  mode,
  companies,
  skipAndTakeQueryStats,
  countriesOptions,
  companiesInBasket,
  setCompaniesInBasket,
  companyActivities,
  paginationQuery,
  setPaginationQuery,
  addCompanyToGroup,
}) => {
  const { formatMessage } = useIntl();

  const pageSize = paginationQuery?.take ?? 10;
  const calculateSkipFromPageNoAndSize = (pageNo: number, pageSize: number) => {
    return (pageNo - 1) * pageSize;
  };

  return (
    <div data-test-id="group-modal-companies-list">
      <div>
        <Typography
          variant="body-regular"
          data-test-id="group-modal-list-result-count"
        >
          {`${skipAndTakeQueryStats?.totalCount} ${formatMessage({
            id: "general.results",
          })}`}
        </Typography>
      </div>
      <div className={styles.cardsContainer}>
        {companies?.map(company => (
          <CompanyCard
            mode={mode}
            key={company.companyId}
            company={company}
            countriesOptions={countriesOptions}
            companiesInBasket={companiesInBasket}
            setCompaniesInBasket={setCompaniesInBasket}
            companyActivities={companyActivities}
            addCompanyToGroup={addCompanyToGroup}
          />
        ))}
      </div>
      <div className={styles.paginationContainer}>
        <Pagination
          defaultPageSize={pageSize}
          total={skipAndTakeQueryStats?.totalCount}
          onChange={(page, pageSize) => {
            const skip = calculateSkipFromPageNoAndSize(page, pageSize);
            setPaginationQuery({ skip, take: pageSize });
          }}
          current={Math.floor(paginationQuery.skip / paginationQuery.take) + 1}
          align="end"
        />
      </div>
    </div>
  );
};

export default CompaniesList;
