import React from "react";
import { useIntl } from "react-intl";

import { Button, Heading, Illustration } from "@trace-one/design-system";
import { Modal } from "antd";

import styles from "./Modal.module.less";
export interface RemoveFromGroupModalModalProps {
  setIsModalVisible?: any;
  isModalVisible?: boolean;
  companyDisplayName?: string;
  handleRemoveFromGroup?: any;
}

const RemoveFromGroupModal: React.FC<RemoveFromGroupModalModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  companyDisplayName,
  handleRemoveFromGroup,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Modal
        wrapClassName={styles.root}
        title={
          <div className={styles.header}>
            <Illustration name="delete" color="grey" />
            <Heading size="xs">
              {formatMessage({ id: "groupDetailsPage.removeFromGroup" })}
            </Heading>
          </div>
        }
        open={isModalVisible}
        footer={
          <>
            <Button
              type="secondary"
              htmlType="submit"
              onClick={() => setIsModalVisible(false)}
            >
              {formatMessage({ id: "general.cancel" }).toUpperCase()}
            </Button>
            <Button htmlType="submit" onClick={handleRemoveFromGroup}>
              {formatMessage({ id: "general.confirm" }).toUpperCase()}
            </Button>
          </>
        }
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
      >
        {formatMessage(
          {
            id: "groupDetailsPage.removeFromGroup.text",
          },
          {
            companyDisplayName: companyDisplayName,
          }
        )}
      </Modal>
    </div>
  );
};

export default RemoveFromGroupModal;
