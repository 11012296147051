import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Button, Heading, Input, toaster } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Form, Modal } from "antd";

import { CumdAPI } from "../../apis";

import styles from "./UpdateGroupModal.module.less";
export interface UpdateGroupModalProps {
  setIsModalVisible?: any;
  isModalVisible?: boolean;
  existingGroupName?: string;
  existingGroupCode?: string;
  companyGroupId?: string;
}

const UpdateGroupModal: React.FC<UpdateGroupModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  existingGroupName,
  existingGroupCode,
  companyGroupId,
}) => {
  const { formatMessage } = useIntl();

  const [groupName, setGroupName] = useState<string>(existingGroupName);
  const [groupCode, setGroupCode] = useState<string>(existingGroupCode);

  const onClose = () => {
    setIsModalVisible(false);
    setGroupName(existingGroupName);
    setGroupCode(existingGroupCode);
  };
  const handleUpdateGroup = async () => {
    try {
      await CumdAPI.updateGroup(companyGroupId, {
        groupName: groupName,
        groupCode: groupCode,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        type: "confirmation",
      });

      setIsModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  return (
    <div>
      <Modal
        wrapClassName={styles.root}
        title={
          <div className={styles.header}>
            <Heading size="s">
              {formatMessage({ id: "groupUpdate.modal.title" })}
            </Heading>
          </div>
        }
        open={isModalVisible}
        footer={
          <>
            <Button
              type="secondary"
              htmlType="submit"
              onClick={() => onClose()}
              data-test-id="group-update-modal-close-button"
            >
              {formatMessage({ id: "general.cancel" }).toUpperCase()}
            </Button>
            <Button
              htmlType="submit"
              onClick={() => handleUpdateGroup()}
              disabled={
                groupName === "" ||
                (groupName === existingGroupName &&
                  groupCode === existingGroupCode)
              }
              data-test-id="group-update-modal-confirm-button"
            >
              {formatMessage({ id: "general.confirm" }).toUpperCase()}
            </Button>
          </>
        }
        onCancel={() => onClose()}
        destroyOnClose={true}
        data-test-id="group-update-modal"
      >
        <div className={styles.wrapper}>
          <Label
            required
            title={formatMessage({
              id: "groupList.table.groupName",
            })}
          >
            <Form.Item
              name="groupName"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
            >
              <Input
                onChange={e => setGroupName(e.target.value)}
                defaultValue={groupName}
                data-test-id="group-update-modal-groupName"
              />
            </Form.Item>
          </Label>

          <Label
            title={formatMessage({
              id: "groupList.table.groupCode",
            })}
          >
            <Form.Item name="groupCode">
              <Input
                onChange={e => setGroupCode(e.target.value)}
                defaultValue={groupCode}
                data-test-id="group-update-modal-groupCode"
              />
            </Form.Item>
          </Label>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateGroupModal;
