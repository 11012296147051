import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  Button,
  Input,
  Heading,
  Select,
  toaster,
} from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Modal, Form } from "antd";
import validator from "validator";

import { CumdAPI } from "../../../../../../../apis";
import { UserData } from "../../../../../../../models";
import { useAppDispatch } from "../../../../../../../reduxStore";
import { fetchJobTitles } from "../../../../../../../reduxStore/shared/asyncActions";
import { selectJobTitlesData } from "../../../../../../../reduxStore/shared/selectors";
import { selectUserLanguageCode } from "../../../../../../../reduxStore/user/selectors";
import { Titles } from "../../../../../../../shared/constants";

import styles from "./EditUserInfo.module.less";

interface EditUserInfoProps {
  user: UserData;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
}

const EditUserInfo: React.FC<EditUserInfoProps> = ({
  user,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectUserLanguageCode);
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [title, setTitle] = useState(user?.userCivility);
  const [firstName, setFirstName] = useState<string>(user?.userFirstName);
  const [lastName, setLastName] = useState<string>(user?.userLastName);
  const [jobTitle, setJobTitle] = useState(user?.userJobTitle);
  const [notificationEmail, setNotificationEmail] = useState<string>(
    user?.userNotificationEmail
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(user?.userPhone);

  const [isNotifEmailValid, setIsNotifEmailValid] = useState(true);

  const titleOptions = Titles.map(title => ({
    value: title,
    label: title,
  }));

  const jobTitlesOptions = useSelector(selectJobTitlesData).data.map(
    ({ itemCode, text }) => ({
      value: itemCode,
      label: text,
    })
  );

  useEffect(() => {
    dispatch(
      fetchJobTitles({
        languageCode,
      })
    );
  }, []);
  const closeModal = () => {
    setIsModalVisible(false);
    setTitle(user?.userCivility);
    setFirstName(user?.userFirstName);
    setLastName(user?.userLastName);
    setJobTitle(user?.userJobTitle);
    setNotificationEmail(user?.userNotificationEmail);
    setPhoneNumber(user?.userPhone);
  };

  const disableBtn =
    title === null ||
    firstName === "" ||
    lastName === "" ||
    jobTitle === undefined ||
    !isNotifEmailValid ||
    notificationEmail === "";

  const handleEditUserInformation = async () => {
    try {
      await CumdAPI.updateUserProfile(user?.userId, {
        newTitle: title,
        newFirstName: firstName,
        newLastName: lastName,
        newNotificationEmail: notificationEmail,
        newJobTitle: Number(jobTitle),
        newPhone: phoneNumber,
      });

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "toast.confirmation.savingDescription",
        }),
        type: "confirmation",
      });

      closeModal();

      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({ id: "general.could.not.be.saved" }),
        type: "alert",
      });
    }
  };

  const handleNotifEmailChange = e => {
    const email = e.target.value;
    setNotificationEmail(email);

    if (email === "") {
      setIsNotifEmailValid(true); // Reset validation if email is empty
    } else {
      setIsNotifEmailValid(validator.isEmail(email)); // Validate email format only if not empty
    }
  };

  return (
    <Form name="basic" form={form} preserve={false}>
      <Modal
        wrapClassName={styles.root}
        title={
          <Heading size="xs">
            {formatMessage({ id: "userUpdate.title" })}
          </Heading>
        }
        open={isModalVisible}
        footer={
          <Button
            htmlType="submit"
            onClick={handleEditUserInformation}
            disabled={disableBtn}
          >
            {formatMessage({ id: "general.save" })}
          </Button>
        }
        onCancel={closeModal}
        destroyOnClose={true}
      >
        <div className={styles.wrapper}>
          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.title" })} required>
              <Form.Item name="userTitle">
                <Select
                  placeholder={formatMessage({
                    id: "general.selectValue",
                  })}
                  showSearch
                  allowClear
                  options={titleOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setTitle}
                  defaultValue={title}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.firstName" })} required>
              <Form.Item name="userFisrtName">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                  defaultValue={firstName}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.lastName" })} required>
              <Form.Item name="userLastName">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                  defaultValue={lastName}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.jobTitle" })} required>
              <Form.Item name="userJobTitle">
                <Select
                  placeholder={formatMessage({
                    id: "general.selectValue",
                  })}
                  showSearch
                  allowClear
                  options={jobTitlesOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={setJobTitle}
                  defaultValue={jobTitle}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label
              title={formatMessage({ id: "general.notificationEmail" })}
              required
            >
              <Form.Item
                name="notificationEmail"
                help={
                  !isNotifEmailValid
                    ? formatMessage({
                        id: "toast.error.invalid.email.description",
                      })
                    : null
                }
                validateStatus={!isNotifEmailValid ? "error" : undefined}
              >
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={handleNotifEmailChange}
                  defaultValue={notificationEmail}
                />
              </Form.Item>
            </Label>
          </div>

          <div className={styles.itemWrapper}>
            <Label title={formatMessage({ id: "general.phone" })}>
              <Form.Item name="phoneNumber">
                <Input
                  placeholder={formatMessage({
                    id: "general.enterValue",
                  })}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                  }}
                  defaultValue={phoneNumber}
                />
              </Form.Item>
            </Label>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default EditUserInfo;
