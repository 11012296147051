import { useEffect, useState } from "react";

import { RlmdAPI } from "../../../apis";
import useToast from "../useToast";

const useCompanyActivities = languageCode => {
  const toast = useToast();

  const [companyActivities, setCompanyActivities] = useState([]);

  const fetchCompanyActivities = async () => {
    try {
      const { data: companyActivities } =
        await RlmdAPI.getReferenceListItemsByReferenceListName(
          "Company_Activity",
          {
            languageCode,
          }
        );
      setCompanyActivities(companyActivities);
    } catch (error) {
      toast.saveError({ error });
    }
  };

  useEffect(() => {
    fetchCompanyActivities();
  }, [languageCode]);

  return companyActivities;
};

export default useCompanyActivities;
