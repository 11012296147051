import { useState } from "react";
import { useIntl } from "react-intl";

import { toaster } from "@trace-one/design-system";
import { Menu } from "antd";

import ActionDropdown from "components/ActionDropdown";

import { CumdAPI } from "../../../../../apis";
import UpdateGroupModal from "../../../../../components/UpdateGroupModal";

import ArchiveGroupModal from "./components/ArchiveGroupModal";

interface GroupListActionsProps {
  companyGroupId?: string;
  setIsUpdateGroupModalOpen?: any;
  groupName?: string;
  groupCode?: string;
  setExistingCompanyGroupId?: any;
  setExistingGroupName?: any;
  setExistingGroupCode?: any;
}

const GroupListActions: React.FC<GroupListActionsProps> = ({
  companyGroupId,
  setIsUpdateGroupModalOpen,
  groupName,
  groupCode,
  setExistingCompanyGroupId,
  setExistingGroupName,
  setExistingGroupCode,
}) => {
  const { formatMessage } = useIntl();

  const [isArchiveGroupModalVisible, setIsArchiveGroupModalVisible] =
    useState<boolean>(false);
  const [isUpdateGroupModalVisible, setIsUpdateGroupModalVisible] =
    useState<boolean>(false);
  const handleRemoveGroup = async () => {
    try {
      await CumdAPI.closeCompanyGroup(companyGroupId);

      toaster.open({
        message: formatMessage({
          id: "toast.confirmation",
        }),
        description: formatMessage({
          id: "groupDetailsPage.toast.confirmation.text",
        }),
        type: "confirmation",
      });

      setIsArchiveGroupModalVisible(false);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } catch (error) {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({
          id: "general.could.not.be.saved",
        }),
        type: "alert",
      });
    }
  };

  const handleAddToGroupModal = () => {
    setIsUpdateGroupModalOpen(true);

    setExistingCompanyGroupId(companyGroupId);
    setExistingGroupName(groupName);
    setExistingGroupCode(groupCode);
  };

  return (
    <>
      <ActionDropdown
        data-test-id="ad-group-list-actions"
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => handleAddToGroupModal()}
              key="add-new-company-group"
              data-test-id="group-list-actions-add-new-company-group"
              style={{ fontSize: "16px", lineHeight: "24px" }}
            >
              {formatMessage({
                id: "general.addNewCompany",
              })}
            </Menu.Item>

            <Menu.Item
              onClick={() => setIsUpdateGroupModalVisible(true)}
              key="edit-group"
              data-test-id="group-list-actions-edit-group"
              style={{ fontSize: "16px", lineHeight: "24px" }}
            >
              {formatMessage({
                id: "general.edit",
              })}
            </Menu.Item>

            <Menu.Item
              onClick={() => setIsArchiveGroupModalVisible(true)}
              key="archive-group"
              data-test-id="group-list-actions-archive-group"
              style={{ fontSize: "16px", lineHeight: "24px" }}
            >
              {formatMessage({
                id: "groupList.actions.archive",
              })}
            </Menu.Item>
          </Menu>
        }
      />

      <ArchiveGroupModal
        isModalVisible={isArchiveGroupModalVisible}
        setIsModalVisible={setIsArchiveGroupModalVisible}
        handleRemoveGroup={handleRemoveGroup}
      />

      <UpdateGroupModal
        isModalVisible={isUpdateGroupModalVisible}
        setIsModalVisible={setIsUpdateGroupModalVisible}
        existingGroupCode={groupCode}
        existingGroupName={groupName}
        companyGroupId={companyGroupId}
      />
    </>
  );
};

export default GroupListActions;
