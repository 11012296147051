import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, Input, Select, toaster } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { Modal, Form } from "antd";

import { CumdAPI } from "../../../../../../apis";
import { CompanyForTraceoneAdminData } from "../../../../../../models";
import { selectCompanyActivitiesData } from "../../../../../../reduxStore/shared/selectors";
import useToast from "../../../../../../shared/hooks/useToast";

import styles from "./EditCompanyInfo.module.less";

interface EditCompanyInfoProps {
  company: CompanyForTraceoneAdminData;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  companyType: string;
  refetchData: () => void;
  refetchCompanies: () => void;
}

const EditCompanyInfo: React.FC<EditCompanyInfoProps> = ({
  company,
  isModalVisible,
  setIsModalVisible,
  companyType,
  refetchData,
  refetchCompanies,
}) => {
  const [newName, setNewName] = useState<string>(null);
  const [newCompanyActivityId, setNewCompanyActivityId] =
    useState<number>(null);

  const { formatMessage } = useIntl();
  const toast = useToast();
  const [form] = Form.useForm();

  const companyActivities = useSelector(selectCompanyActivitiesData);

  const companyActivityOptions = companyActivities.data.map(activity => ({
    name: activity.text,
    value: activity.itemCode,
  }));

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (company) {
      setNewName(company.companyDisplayName);
      setNewCompanyActivityId(company.companyActivityId);
    }
  }, [company, isModalVisible]);

  useEffect(() => {
    form.resetFields(["companyName", "companyActivity"]);
  }, [isModalVisible]);

  const handleSubmit = async () => {
    if (newName !== "") {
      try {
        await CumdAPI.updateCompanyProfile(company?.companyId, {
          newDisplayName: newName,
          companyActivity: newCompanyActivityId,
        });
      } catch (error) {
        toast.saveError({ error });
      }
      refetchData();
      refetchCompanies();
      setIsModalVisible(false);
    } else {
      toaster.open({
        message: formatMessage({ id: "toast.alert" }),
        description: formatMessage({ id: "general.could.not.be.saved" }),
        type: "alert",
      });
    }
  };

  return (
    <Form name="basic" form={form} onFinish={handleSubmit}>
      <Modal
        wrapClassName={styles.root}
        title={
          <h1 className={styles.title}>
            {formatMessage({ id: "companyGeneralInfo.editInfo.title" })}
          </h1>
        }
        open={isModalVisible}
        footer={
          <Button htmlType="submit" onClick={handleSubmit}>
            {formatMessage({ id: "general.save" })}
          </Button>
        }
        onCancel={closeModal}
      >
        <div className={styles.wrapper}>
          <Label
            required
            title={formatMessage({
              id: "companyGeneralInfo.companyDisplayName",
            })}
          >
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: "general.error.mandatory.field",
                  }),
                },
              ]}
              initialValue={newName}
            >
              <Input onChange={e => setNewName(e.target.value)} />
            </Form.Item>
          </Label>

          <Label
            title={formatMessage({
              id: "general.companyType",
            })}
          >
            <Form.Item
              name="companyActivity"
              initialValue={newCompanyActivityId?.toString()}
            >
              <Select
                disabled={companyType === "Retailer"}
                options={companyActivityOptions}
                onChange={setNewCompanyActivityId}
                allowClear={false}
              />
            </Form.Item>
          </Label>
        </div>
      </Modal>
    </Form>
  );
};

export default EditCompanyInfo;
